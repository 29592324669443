.group-list-divider {
    height: 25px;
}

.divider-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -2px;
    margin-left: 10px;
}

.group-list {
    position: relative;
    -webkit-overflow-scrolling: touch;
}

.group-spacing {
    margin-top: 22px;
}

@media(max-width: 1023) {
  .group-text-spacing {
      margin-left: 0px;
  }
}

@media(min-width: 1024) {
  .group-text-spacing {
      margin-left: 5px;
  }
}

.group-text-center {
    margin-left: -25%;
}

@media(max-width: 1023px) {
  .group-text-center {
      margin-left: 28%;
  }
}
@media(min-width: 1024px) {
  .group-text-center {
      margin-left: 35%;
  }
}

.group-element {
    margin-bottom: 10px;
    word-break: break-word;
    overflow-x: hidden;
}

.groups-width {
    width: 100%;
}

@media (max-width: 1023px) {
  .left-banned-text {
      margin-left: 0px;
      margin-bottom: -3px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-banned-text {
      margin-left: 0px;
      margin-bottom: -3px;
      color: black;
  }
}

@media (max-width: 1023px) {
  .left-banned-box-spacing {
      margin-top: -8px;
      margin-left: -20px;
  }
}

@media (min-width: 1024px) {
  .left-banned-box-spacing {
      margin-top: -8px;
  }
}

@media (max-width: 1023px) {
    .left-value-verified-line1 {
        margin-left: -4%;
        margin-bottom: -3px;
        color: black;
    }
}

@media (max-width: 1023px) {
    .left-value {
        margin-left: 0%;
        margin-bottom: 5px;
        color: black;
    }
}

@media (max-width: 1023px) {
  .left-value-verified2 {
      margin-left: -1%;
      margin-bottom: 0px;
      font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .left-value-verified2 {
      margin-left: 0%;
      margin-bottom: 0px;
      font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .left-value-verified1 {
      margin-left: -1%;
      margin-bottom: 0px;
      font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .left-value-verified1 {
      margin-left: 0%;
      margin-bottom: -16px;
      font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .left-value-verified {
      margin-left: -4%;
      margin-bottom: -14px;
      color: black;
  }
}

@media (max-width: 1023px) {
  .left-value-verified-secondary {
      margin-left: -4%;
      margin-bottom: 15px;
      color: black;
  }
}

@media (min-width: 1024px) {
    .left-value {
        margin-left: 5%;
        margin-bottom: 5px;
        color: black;
    }
}

@media (min-width: 1024px) {
  .left-value-verified {
      margin-left: 0%;
      margin-bottom: -16px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-value-verified-line1 {
      margin-left: 0%;
      margin-bottom: -3px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-value-verified-secondary {
      margin-left: 0%;
      margin-bottom: 15px;
      color: black;
  }
}

@media (min-width: 1024px) {
    .left-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-left:20px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-right: -150px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-right: -180px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist1{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-left: -35px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist1{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-left: -85px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist2{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-right: 0px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist2{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-right: 0px;
    }
}

@media (max-width: 1023px) {
    .right-value {
        margin-top: 5px;
        margin-right: -60%;
        margin-bottom: 5px;
    }
}

@media (min-width: 1024px) {
    .right-value {
        margin-top: 5px;
        margin-right: -40%;
        margin-bottom: 5px;
    }
}

@media (max-width: 1023px) {
    .right-value-verified2 {
        margin-top: 3px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified2 {
        margin-top: 5px;
        margin-right: -37%;
    }
}

@media (max-width: 1023px) {
    .right-value-verified1 {
        margin-top: 7px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified1 {
        margin-top: 7.3px;
        margin-right: -37%;
        margin-bottom: 4px;
    }
}

@media (max-width: 1023px) {
    .right-value-verified {
        margin-top: 7px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified {
        margin-top: 7.3px;
        margin-right: -37%;
        margin-bottom: 4px;
    }
}

@media (max-width: 1023px) {
    .right-value1 {
        padding-bottom: 20px;
        margin-left: -7%;
    }
}

@media (min-width: 1024px) {
    .right-value1 {
        margin-top: 5px;
        margin-right: -40%;
    }
}

.status-italics {
    font-style: italic;
    font-size: 16px;
}

.gray {
    color: gray;
}

.count {
    font-size: 15px;
}

.header-padding {
    padding-top: 50px;
}

.filter-line {
    font-size: 20px;
    font-weight: bold;
}

.title-line {
    font-size: 19px;
    font-weight: bold;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.second-title-line {
    font-size: 17px;
    font-weight: bold;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.reorder-title-line {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
}

.reorder-title {
    margin-right: -1px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 3px; /* here only to align bottom of text with title-line Features/Favorites due to different font size */
}

.group-title {
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 1023px) {
  .group-right-title {
      font-size: 16px;
      margin-top: 2px;
  }
}

@media (min-width: 1024px) {
  .group-right-title {
      font-size: 16px;
      padding-top: 5px;
  }
}

@media (max-width: 1023px) {
  .main-group-seeAll-title {
      font-size: 16px;
      margin-left: -100px;
      padding-top: 0px;
  }
}

@media (min-width: 1024px) {
  .main-group-seeAll-title {
      font-size: 16px;
      margin-left: -100px;
      padding-top: 5px;
  }
}

@media (max-width: 1023px) {
  .manage-favorites-settings-icon {
        position: absolute;
        right: 4%;
        padding-top: 2px;
        height: 20px;
        width: 25px;
        cursor: pointer;
        border-radius: 5px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.8;
        background: white;
  }
}

@media (min-width: 1024px) {
  .manage-favorites-settings-icon {
        position: absolute;
        right: 3%;
        padding-top: 2px;
        padding-bottom: 2px;
        height: 25px;
        width: 36px;
        cursor: pointer;
        border-radius: 5px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.8;
        background: white;
  }
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-overflow {
    overflow-x: hidden;
}

/*.group-container {
    background-color: white;
    color: black;
} */

.subgroup-arrow {
    transform: rotate(180deg);
    height: 15px;
    margin-right: 5px;
}

.group-search-input {

    appearance: none;
    width: 100%;
    border-radius: 0;
    background: transparent;
    color: white;
    border: none;
    outline: none !important;
    align-self: center;
    outline-color: transparent;
    outline-style: none;
  }

.x-icon {
    position: absolute;
    right: 0;
    bottom: 53px;
    cursor: pointer;
    z-index: 1;

    height: calc(var(--vh, 1vh) * 3);
}

/* reorder-cancel-button (all) and reorder-save-button (all) need to modify button layout area (persistent) so can move the buttons
to fix UI layout - right now when moved, cancel button doesn't trigger */
/* Also right now these (all) are fit to my mac and my iphone XS so need to check/update for other device sizes */
@media (max-width: 1023px) {
  .reorder-cancel-button {
    margin-left: 80%;
    width: 80px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .reorder-cancel-button {
    margin-left: 80%;
    width: 120px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (max-width: 1023px) {
  .reorder-save-button {
    margin-right: -70%;
    width: 80px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .reorder-save-button {
    margin-right: 40%;
    width: 120px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .title-align {
    margin-left: 250px;
  }
}

@media (max-width: 1023px) {
  .title-align-reorder-left {
    margin-left: 0px;
  }
}

@media (min-width: 1024px) {
  .title-align-reorder-left {
    margin-left: 100px;
  }
}

@media (max-width: 1023px) {
  .circle-green {
    height: 20px;
    width: 20px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-green {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: green;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-blue {
    height: 20px;
    width: 20px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-blue {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: blue;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-yellow {
    height: 20px;
    width: 20px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-yellow {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: yellow;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-red {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-red {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: red;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-filled {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-filled {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle {
    height: 20px;
    width: 20px;
    border: solid 1px;
    border-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle {
    height: 20px;
    width: 20px;
    margin-top: -50px;
    border: solid 1px;
    border-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

.divider-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -2px;
    margin-left: 10px;
}

@media(max-width: 1023px) {
  .divider-circle1 {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 4px;
      margin-left: 0px;
  }
}

@media(max-width: 1023px) {
  .divider-circle2 {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 11px;
      margin-left: 0px;
  }
}

@media(min-width: 1024px) {
  .divider-circle1 {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-top: -2px;
      margin-left: 10px;
  }
}

@media(min-width: 1024px) {
  .divider-circle2 {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-top: -4px;
      margin-left: 10px;
  }
}

.search {
    height: 20px;
}

.poptions {
    pointer-events: none;
}

.move-right {
    margin-left: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 10px;
}

@media (max-width: 1023px) {
  .publish-background {
      background-color: black;
      margin-top: 45px;
  }
}

@media (min-width: 1024px) {
  .publish-background {
      background-color: black;
      margin-top: 25px;
      margin-left: 12%;
  }
}

.options {
    position: sticky;
    bottom: 15px;
    right: 15px;
    width: 100%;
    z-index: 120000;
}

.options-open {
    position: sticky;
    bottom: 15px;
    right: 15px;
    width: 100%;
}

.stop-scroll {
    overflow: hidden;
}

.blurred {
    filter: blur(5px)
}

.group-menu-icon {
    width: 37px;
    height: 37px;
    margin: 5px;
}

.letter-line {
    background: -moz-linear-gradient(left,  rgba(198,198,198,1) 0%, rgba(252,252,252,0) 97%);
    background: -webkit-linear-gradient(left,  rgba(198,198,198,1) 0%,rgba(252,252,252,0) 97%);
    background: linear-gradient(to right,  rgba(198,198,198,1) 0%,rgba(252,252,252,0) 97%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6c6c6', endColorstr='#00fcfcfc',GradientType=1 );
}

.pl-15 {
    padding-left: 15px;
}

.fixed-header-height {
    height: 50px;
}

.home-navigation {
    position: relative;
}

.favorite-button {
    position:absolute;
    right: 15px;
    cursor: pointer;
    width: 20px;
    z-index: 5000;
}

.favorite-button.plus {
    width: 30px;
    z-index: 5000;
}

@media (min-width: 320px) {
    .favorite-button {
        top: -5px;
        right: 0px;
    }
}

@media (min-width: 375px) {
    .favorite-button {
        top: -5px;
        right: 7px;
    }
}

@media (min-width: 768px) {
    .favorite-button {
        right: 20px;
    }
}

@media (min-width: 1024px) {
    .favorite-button {
        right: 5px;
    }
}

@media (min-width: 1440px) {
    .favorite-button {
        right: 15px;
    }
}

.profile-icon {
    height: 30px;
    width: 30px;
    border-radius: 25px;
}

.public-button {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    z-index: 1000;
}

.option-img.plus {
    transform: rotate(45deg);
}

.option-img.minus {
    transform: rotate(90deg);
    margin-top: 7px;
    margin-right: 7px;
}

@media (max-width: 1023px) {
  .filter-page {
      font-size: 16px;
      margin-right: -240px;
  }
}

@media (max-width: 1023px) {
  .group-circle {
      margin-right: 225px;
  }
}

@media (max-width: 1023px) {
  .group-circle-featured-filter-line {
      margin-right: 0px;
  }
}

@media (min-width: 1024px) {
  .group-circle-featured-filter-line {
      margin-right: 250px;
  }
}

@media (max-width: 1023px) {
  .reorder-favorites-right {
      margin-left: 30%;
  }
}

@media (min-width: 1024px) {
  .reorder-favorites-right {
      margin-right: 250px;
  }
}

@media (min-width: 1024px) {
  .filter-page {
      font-size: 20px;
      margin-left: 236px;
  }
}

@media (min-width: 1024px) {
  .group-circle {
      margin-right: 250px;
  }
}

.lightgrey {
    color: #D3D3D3;
}

.blue {
    color: blue;
}

.lighterblue {
    color: #0000FF;
    font-size: 18px;
}

.account-left {
    margin-left: -80px;
}

.category {
    font-size: 12px;
}

.capsule-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media(max-width: 1023px) {
  .black {
      color: black;
      font-size: 16px;
      margin-left: 2px;
  }
}

@media(min-width: 1024px) {
  .black {
      color: black;
      font-size: 18px;
      margin-left: 2px;
  }
}
