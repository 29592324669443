@viewport{
  zoom: 1.0;
  width: device-width;
  height: device-height;
}

html {
  position: relative;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  -webkit-overflow-scrolling: touch;
  
}

img {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overflow-none {
  overflow-y: hidden !important;
}

.hover:hover {
  cursor: pointer;
}

.background {
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
}

.hidden {
  display: none !important;
}

.navigation-button:hover {
  color: darkgray;
  text-decoration: none;
}

.navigation-button:active {
  color: grey;
  text-decoration: none;
}

.navigation-button {
  cursor: pointer;
  color: auto;
  text-decoration: none;
}

.text-grey {
  color: grey;
}

.modal-content {
  border-radius: 15px;
  max-height: 75vh;
}

.video-playback .modal-content {
  background-color: rgba(0,0,0,0);
  border: none;
  text-align: center;
}


