.hidden {
    visibility: hidden;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-processing .modal-content {
    bottom: 150px;
    min-height: 150px !important;
}

.modal-playback {
    max-height: 75vh !important;
}

.close-video {
    position: absolute;
    right: 5%;
    z-index: 5000;
    cursor: pointer;
}

.video-plyr {
    outline: none !important;
}