.edit-option {
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.preview-option {
    border: 1px solid white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.active {
    opacity: 0.8;
    background-color: white;
    color: black;
}

.header-options {
    padding-top: 15px;
    padding-bottom: 15px;

}

.header-options.preview {
    padding-top: 15px;
    padding-bottom: 10px;
}

.instructions-text {
    text-align: center;
}

@media (max-width: 414px) {
    .last-name {
        margin-top: 10px !important;
     }
  }


.set-background-container {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden !important;
}

.create-capsule-divider {
    width: 100%;
}

.capsule-input {
    appearance: none;
    text-overflow: ellipsis;
    border-radius: 0;
    background: transparent;
    border: none;
    color: black;
    border-bottom: 1px solid grey;
    outline: none !important;
    align-self: center;
    width: 100%;
    outline-color: transparent;
    outline-style: none;
}

.capsule-input.textarea {
    height: 100%;
    overflow: hidden;
    resize: none;
    white-space: nowrap;
}

.capsule-input.category {
    font-size: 14px;
    margin-top: 10px;
}

.capsule-input::placeholder {
    color: grey;
    font-size: 14px;

}

.capsule-header {
    padding-bottom: 15px;
}

.capsule-header.vcard {
    text-align: center;
    padding-top: 10px;
    font-size: 17px;
}

.vCard-icons {
    max-height: 32px;
    max-width: 32px;
}

.input-row {
    padding-bottom: 15px;
}

.input-row-title {
    padding-bottom: 14px;
    font-size: 16px;
    word-wrap: break-word;
}

.input-row-title1 {
    padding-bottom: 5px;
    font-size: 16px;
    word-wrap: break-word;
}

.website-width {
    width: 96%;
}

.show-more {
    text-align: center;
    font-size: 13px;
}

.show-more.opened {
    padding-bottom: 15px;
}

.connect-button {
    text-align: center;
    border: 1px solid white;
    border-radius: 20px;
    opacity: 0.5;
}

@media (max-width: 575px) {
    .connect-button {
        margin-top: 20px;
    }
  }

  @media (max-width: 575px) {
    .vCard-icons {
        margin-bottom: 10px;
    }
  }

  .reorder-button {
    background: white;
    background: -webkit-linear-gradient(white, #293f50);
    background: -moz-linear-gradient(white, #293f50);
    background: linear-gradient(white, #293f50);
    padding-left: 15px;
    border-radius: 20px;
    width: 75%;
    opacity: 0.5;
    margin: auto;
    cursor: pointer;
    text-align:center;
  }

  .create-card {
    border: 1px solid white;
    border-radius: 20px;
    font-size: 20px;
    width: 75%;
    margin: 30px auto;
  }

  .save-button {
      text-align: right;
  }

  .vCard-icons.add-remove {
    max-height: 25px;
    max-width: 25px;
    padding-right: 15px;
  }

  .no-padding {
    padding-left: 15px;
  }

  .media-image {
    height: 60px;
    width: auto;
      cursor: pointer;
      border-radius: 10px;
  }

  .media-list-image {
    height: 100px;
    width: 100px;
      cursor: pointer;
      border-radius: 10px;
  }
  @media (max-width: 320px) {

  .media-list-image {
    height: 75px;
    width: 75px;
      cursor: pointer;
      border-radius: 10px;
  }
}

  .media-image.document {
      height: 60px;
      width: auto;
  }

  .photos {
      text-align: center;
  }

  .media-option {
      cursor: pointer;
  }

  .small-text {
      font-size: 12px;
  }

  .settings-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .profile-divider {
      width: 100%;
  }

  .delete-button {
      margin-top: 15px;
      cursor: pointer;
      color: red;
  }

  .bottom-button {
    border: 1px solid white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .manage-icons {
      max-height: 25px;
      max-width: 25px;
      cursor: pointer;
  }


  .manage-icons.add-remove {
    max-height: 25px;
    max-width: 25px;
    margin-right: 15px;
  }

  .edit-menu-option {
      margin-right: 30px;
      text-align: center;
      cursor: pointer;
  }

  .social-header {
      padding-bottom: 15px;
  }

  .mr-15 {
      margin-right: 15px;
  }

  .mb-10 {
      margin-bottom: 15px;
  }

  .capsules-container {
    height: calc(var(--vh, 1vh) * 65);
    overflow: auto;
    overflow-x: hidden;

  }

.capsules-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.capsules-container::-webkit-scrollbar:vertical {
    width: 12px;
}

.capsules-container::-webkit-scrollbar:horizontal {
    height: 12px;
}

.capsules-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.capsules-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.color-grey {
    color: rgb(129, 128, 128);
    border-bottom: 1px solid grey
}

.slash {
    color: darkgrey;
}

.section-card {
    background: rgba(255,255,255,0.1);

    filter: blur('5px');
    border-radius: 15px;
    color: white !important;
}

.profile-image {
    height: 35px;
    width: auto;
    float: left;
}

.profile-image.password {
    height: 40px;
}

.profile-row {
    margin-left: 5px;
    margin-top: 20px;
}

.profile-card {
    border-radius: 15px;
    color: grey;
    background-color: white;
}

.radius {
    border-radius: 10px;
}

/* profile icon in Edit and Profile-profile section*/
.profile-photo-icon {
    max-width: 120px;
    height: auto;
    margin-left: -10px;;
    margin-top: -10px;
}

/* Profile image in Edit and Profile-profile section*/
.profile-edit-photo {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  margin-left: 2px;
  margin-top: 6px;
}

/* capsule icon*/
.capsule-photo-icon {
    max-width: auto;
    height: 190px;
    margin-top: -20px;
}

/* capsule image size in Edit */
.capsule-edit-photo {
  height: 140px;
  width: auto;
  margin: 0 auto;
}

/* capsule image size in Profile User section via logic in Edit*/
.profile-section-capsule-img {
    height: 160px;
    width: auto;
    margin: 0 auto;
}

.profile-audio-docs-icon {
    max-width: 100px;
    height: auto;
}

@media (max-width: 769px) {
    .profile-video {
        max-height: 40px;
    }
}

@media (min-width: 769px) {
    .profile-video {
        max-height: 60px;
    }
}

@media (max-width: 575px) {
    .profile-video {
        max-height: 60px;
    }
}


.profile-closed {
    background: white;
    color: black;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;

}

.down-arrow {
    transform: rotate(270deg);
    height: 20px;
}

.mt-10 {
    margin-top:20px;
}

.color-black {
    color: black !important;
}

.button-up {
    transform: rotate(180deg);
}

.section-button {
    height: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.create-title {
    font-size:  15px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 30px;
    font-weight: bold;
}

.button-padding {
    padding-top:100px;
    padding-bottom: 50px;
}

.create-button {
    border: 1px solid;
    border-radius: 100px;
    width: 150px;
}

.create-button-coverURL {
    border: 1px solid;
    border-radius: 100px;
    width: 160px;
}

.create-button.set-group {
    margin-top: 15px;
    width: auto;
}

.create-panel {
    margin-left: 50px;
    margin-right: 50px;
}

.pb-15 {
    padding-bottom: 15px;
  }

  .back-button {
      height: 20px;
  }

.trash-icon {
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.main-section-button {
    transform: rotate(270deg);
    height: 20px;
}

.main-section-button.opened {
    transform: rotate(90deg);
}

.section-icon {
    height: 20px;
    margin-right: 10px;
}

.section-icon.notes {
    height: 15px;
}

.vcard-section {
    margin-bottom: 10px;
}

.capsule-section {
    margin-top: 20px;
    padding-right: 20px;
}

.link-icon {
    height: 50px;
}

.button-row {
    margin-top: 10px;
    margin-bottom: 15px;
}

.row-height-short {
    margin-top: 0px;
    margin-bottom: 0px;
}

.button-row-set {
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.button-option-left {
    margin-left: 60px;
}

.button-option-right {
    margin-left: -20px;
}

.button-option-left-text {
    margin-left: 55px;
    margin-right: -5px;
}

.button-option-right-text {
    margin-left: 10px;
    margin-right: 40px;
}

.button-center {
    margin-left: 42%;
    width: 60px;
    padding-bottom: 10px;
}

.button-left {
    margin-top: -10px;
    margin-right: 30%;
    margin-bottom: 30px;
}

.button-close {
    margin-top: 30px;
    margin-left: 32%;
    margin-bottom: 50px;
}

.row-spacing {
    margin-top: 40px;
    margin-left: 10%;
}

.modal-headers {
    color: grey;
    font-size: 17px;
    border: none;
}

.verify-modal-background {
    background-color: black;
    color: white;
}

.create-button.btn-sm {
    margin-top: 15px;
}

.btn-deleteMe {
    background-color: grey;
    padding-top: 2px;
    padding-bottom: 2px;
    color: black;
    width: auto;
    margin-bottom: 10px;
}

.select-file {
    margin-top: 5px;
    color: #4580fc;
    font-weight: 400;
    cursor: pointer;
}

@media (max-width: 1023px) {
  .type-here {
      font-size: 15px;
      margin-left: 5px;
  }
}

@media (min-width: 1024px) {
  .type-here {
      font-size: 15px;
      margin-left: 0px;
  }
}

.option-icon {
    height: 80px;
}

.url-text-color {
    color: black;
}

.group-edit-container {
    max-height:300px;
    overflow-x: hidden;
}

@media (max-width: 400px) { /*check keith's phone, adjust for larger phones*/
  .verify-users-container {
      max-height: 520px;
      overflow-x: hidden;
  }
}

@media (min-width: 401px) {
  .verify-users-container {
      max-height: 520px;
      overflow-x: hidden;
  }
}

.category {
    text-overflow: ellipsis;
}

.modal-headers.groups {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.url-options {
    margin-right: -100px;
}

.upload-inprogress {
    padding-bottom: 15px;
}

.profile-photo-vcard {
    width: 100px;
}

.capsule-input.groups {
    width: 100%;
    text-overflow: ellipsis;
}

.play-button {
    height: 60px;
    width: 60px;
    background-color: rgba(0,0,0,0.3);
}

.media-status {
    text-align: center;
}

.error-msg {
    color: red;
    font-size: 12px;
}

@media (max-width: 1023px) {
    .remove-group{
      margin-leftt: -50px;
    }
}

.group-switcher {
    border: 1px solid black;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.group-switcher.private {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: none;
}

.group-active {
    color: white;
    background: grey;
}

.delete {
    color: red;
}

.color-white {
    color: white;
}

.share-group-copied {
    font-size: 18px;
    padding-left: 10px;
    margin-top: =20px;
    width: auto;
    height: 50px;
    display: inline-block;
}

.sub-groups {
    font-size: 14px;
    padding-left: 25px;
    color: grey;
}

.subgroup-button {
    width: 25px;
}

.subgroup-button.opened {
    transform: rotate(180deg);
}

.path-arrow {
    transform: rotate(270deg);
    height: 7px;
}

.back-to-top-button {
    transform: rotate(180deg);
    height: 9px;
    width: 15px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 2px;
}

.type-select {
    padding-right: 0;
}

.add-field-icon {
    cursor: pointer;
}

.add-field-icon.plus {
    height: 20px;
}

.add-field-icon.minus {
    height: 5px;
}

.word-break {
    word-break: normal;
}

.modal-copy {
    max-width: 140px;
    margin-left: auto !important;
    margin-right: auto !important;
    top: 50vh;
}

.date-field {
    width: 100%;
}



  @media (max-width: 2560px) {
    .modal-copy {
        top: 450px;
    }
  }

  @media (max-width: 1440px) {
    .modal-copy {
        top: 60vh;
    }
  }

  @media (max-width: 1024px) {
    .modal-copy {
        top: 55vh;
    }

  }

  @media (max-width: 768px) {
    .modal-copy {
        top: 55vh;
    }
  }

  @media (max-width: 425px) {
    .modal-copy {
        top: 55vh;
    }
  }

  @media (max-width: 375px) {
    .modal-copy {
        top: 50vh;
     }
  }

  @media (max-width: 320px) {
    .modal-copy {
        top: 62vh;
     }
  }

  .full-height-modal {
      height: 100%;
  }

  .full-height-modal .modal-content {
      height: 100% !important;
  }

  #name, #category {
      color: white !important;
  }
