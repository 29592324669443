.page-not-show {
    position: absolute;
    bottom: 50%;
    text-align: center
}

.ios-frame {
    border: none;

    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 100%;
    overflow:  hidden;
}

.ios-frameElsForAutism {
    border: none;

    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 100%;
    overflow:  hidden;
    background-color: white;
}

.facebook-align {
    text-align: center;
}

.elsWhiteBackground {
    background: white;
}

.blackBackground {
    background: black;
}
