@viewport{
  zoom: 1.0;
  width: device-width;
  height: device-height;
}

html {
  position: relative;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  -webkit-overflow-scrolling: touch;
  
}

img {
  -webkit-user-select: none;
          user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overflow-none {
  overflow-y: hidden !important;
}

.hover:hover {
  cursor: pointer;
}

.background {
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
}

.hidden {
  display: none !important;
}

.navigation-button:hover {
  color: darkgray;
  text-decoration: none;
}

.navigation-button:active {
  color: grey;
  text-decoration: none;
}

.navigation-button {
  cursor: pointer;
  color: auto;
  text-decoration: none;
}

.text-grey {
  color: grey;
}

.modal-content {
  border-radius: 15px;
  max-height: 75vh;
}

.video-playback .modal-content {
  background-color: rgba(0,0,0,0);
  border: none;
  text-align: center;
}



.responsive {
    width:      100;
    height:     80;
    transition: 'contain';
    margin-top:  3;
    margin-left: -10;
    align-self:  'center'
}

.option:hover {
    cursor: pointer;
}

.option {
    width: auto !important;
    margin-top: 1px;
    height: 25px;
    margin-right:10px;
}

.option.external {
  filter: brightness(5);
  margin-right: 25px;
}

.option.external.first {
  filter: brightness(5);
  margin-left: 5px;
}

.option.external.last {
  filter: brightness(5);
  margin-right: 0px;
}

.option.inactive {
  filter: brightness(0) !important;
  cursor: unset;
}


.facebook-icon {
  padding-left: 5px;
}

.twitter-icon {
  margin-top: 3px;
  height: 21px;
  margin-left: -2px;
}

.opt-cont {
  padding-left: 5px;
}

.main-option {
    width: 35% !important;
    height: 30px;
    margin-left: 3px;
}

.social-carousel {
    padding-left: 44%;
    margin-left: 24px;
}


  @media (min-width: 320px) {
    .options-container {
      padding-left: 10px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -55px;
      position: absolute;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -20px;
      position: absolute;
      width: auto;
      height: 25px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-top: 4px;
      margin-left: 20px;
      position: absolute;
      width: auto;
      height: 22px;
    }
  }

  @media (max-width: 419px) {  /*iphone xs*/
    .options-container {
        padding-left: 15px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -55px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -20px;
      width: auto;
      height: 25px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-top: 4px;
      margin-left: 20px;
      width: auto;
      height: 22px;
    }

  }

@media (min-width: 420px) {   /*xphone 13 pro*/
  .explore-container {
    padding: 0 !important;
    padding-left: 15px;
  }

  .option.external.first {
    filter: brightness(5);
    margin-left: -65px;
  }

  .option.external {
    filter: brightness(5);
    margin-left: -26px;
    width: auto;
    height: 26px;
  }

  .option.external.last {
    filter: brightness(5);
    margin-top: 4px;
    margin-left: 18px;
    width: auto;
    height: 22px;
  }
}

@media (min-width: 1200px) {
  #explore-container {
    width: 70%;
  }

  #explore-container {
    transition: 0.3s ease-in;
    transition-property: all;

  }

  .full-capsule {
    width: 100% !important;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

}

@media (min-width: 900px) {  /*my mal laptop*/
  .options-container {
      padding-left: 19px;
  }

  .option.external.first {
    filter: brightness(5);
    left: 70px;
    position: absolute;
  }

  .option.external {
    filter: brightness(5);
    margin-left: -80px;
    position: absolute;;
  }

  .option.external.last {
    filter: brightness(5);
    margin-left: -30px;
    position: absolute;
  }
}

  @media (min-width: 1024px) { /*mac laptop*/
    .options-container {
      padding-left: 39px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -100px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -100px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-right: 0px;
    }

  }

  @media (min-width: 1440px) {
    .options-container {
      padding-left: 39px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -150px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -140px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-left: -60px;
    }
  }


.capsule-name {
    text-align: left;
}

.active-option {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -3px;
}

.container-width {
  max-width: 650px;
  left: 50%;
}

@media(max-height: 910px) {
    .capsule-view-0 {
      height: calc(var(--vh, 1vh) * 55);
    }
  }

  @media(min-height: 910px) {
    .capsule-view-0 {
      height: calc(var(--vh, 1vh) * 55);
    }

  }

  .capsule-view-0 {
    height: calc(var(--vh, 1vh) * 75);
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .capsule-view-1 {
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: calc(var(--vh, 1vh) * 90);
  }

  .capsule-view-2 {

   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: calc(var(--vh, 1vh) * 95);
  }

  .capsule-view-3 {

    height: calc(var(--vh, 1vh) * 86);
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .capsule-view.opened {
      height: calc(var(--vh, 1vh) * 75);
      overflow: auto;
  }

.divider {
    text-align: center;
    margin: 5px;
    height: calc(var(--vh, 1vh) * 3);
    cursor: pointer;
}

.divider.explore {
  text-align: center;
  margin: 5px;
  height: calc(var(--vh, 1vh) * 0);
  cursor: pointer;
}

.carousel-container.social {
    padding-top: 5px;
    height: calc(var(--vh, 1vh) * 5);
}

.carousel-container.create {
  padding-top: 5px;
  height: calc(var(--vh, 1vh) * 5);
}

.carousel-container.navigation {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: white;
    text-align: center;
}

.header {
    height: calc(var(--vh, 1vh) * 5);
    color: white;
}

.right {
    text-align: right;
}

.left {
  text-align: left;
}

.search-icon-input {
  height: 20px;
  width: 20px;
}

.mute {
  height: 1px;
  position: relative;
  bottom: 35px;
}

.group-search-assets-left {
  border: 1px solid black;
  border-right-style: none;
  background: transparent;
  color: white;
}

.group-search-assets-right {
  border: 1px solid black;
  border-left-style: none;
  background: transparent;
  color: white;
}

.categoryCarousel {
    padding-right: 0px;
    overflow: hidden;
}

.left-icon {
    text-align: left !important;
}

.center-icon {
    text-align: center;
}

.right-icon {
    text-align: right;
}



@media (max-height: 1080px) {
    .navigation-img {
       width: 140px !important;
    }

  }

  @media (max-height: 1024px) {
    .navigation-img {
       width: 118px !important;
    }

  }

  @media (max-height: 841px) {
    .navigation-img {
       width: 105px !important;
    }
  }

@media (max-height: 840px) {
    .navigation-img {
       width: 100px !important;
    }
  }

  @media (max-height: 736px) {
    .navigation-img {
      margin-top: 5px !important;
       width: 95px !important;
    }
  }

  @media (max-height: 731px) {
    .navigation-img {
      margin-top: 6px !important;
       width: 95px !important;
    }
  }

  @media (max-height: 672px) {
    .navigation-img {

        width: 80px !important;
     }
  }

  @media (max-height: 568px) {
    .navigation-img {
      margin-top: 5px !important;
        width: 75px !important;
     }
  }

  @media (max-height: 490px) {
    .navigation-img {
        width: 60px !important;
     }
  }

.active-option.navigation{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    z-index: 1;
    margin-top:2px;
    height: calc(var(--vh, 1vh) * 8);
}

.navigation-container {
  height: calc(var(--vh, 1vh) * 8);
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-img {
  height: calc(var(--vh, 1vh) * 7);
    width: 127px;
    font-size: 12px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 6px;
    margin-left:auto;
    margin-right:auto;

}

.no-pic {
  background-image: url(/static/media/no_pic.8a54047b.jpeg);
  background-size: 100% 100%;
}

.navigation-name-on-pic {

    display: block;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1px;
    font-size:12px;
    cursor: pointer;

}

.p-0 {
    padding: 0;
}

/* Transformation classes for orientation data */

.trans-mirror {
  transform: scaleX(-1);
}

.trans-rotate-180 {
    transform: rotate(180deg);
}

.trans-mirror-rotate-180 {
    transform: scaleX(-1) rotate(180deg);
}

.trans-mirror-rotate-90 {
    transform: scaleX(-1) rotate(90deg);
}

.trans-rotate-90 {
    transform: rotate(90deg);
}

.trans-rotate-270 {
    transform: rotate(270deg);
}

.trans-mirror-rotate-270 {
    transform: scaleX(-1) rotate(270deg);
}

.fullscreen-btn {
  border-radius: 10px;
  background: rgb(171,171,171);
  background: linear-gradient(0deg, rgba(171,171,171,1) 0%, rgba(204,201,201,1) 56%, rgba(255,255,255,1) 100%);
  outline: none;
  border: none;
}

.fullscreen-btn:active {
  background: rgba(171,171,171,0.8);
}

.fullscreen-btn-container {
  padding-top: 8px;
  pointer-events: auto;
  z-index: 10;
}

.gradient-background {
    background: linear-gradient(to right, rgba(238,238,238,0.05) 0%,rgba(255,255,255,0.37) 50%,rgba(252,252,252,0.05) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0deeeeee', endColorstr='#0dfcfcfc',GradientType=1 );

}

.iframe {
    -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      overflow-x: hidden;
     *width: 1px;
    min-width: 100%;

}

iframe {
    *width: 1px;
    min-width: 100%;
    height: 100%;
    width: 100%;

}

.section-background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color:rgba(0, 0, 0, 0.8);
  pointer-events: none
}

.not-draggable {
  pointer-events: none;
  touch-action: none;
}

.bot-padding {
  padding-left: 0px;
  padding-right: 0px;
}

#lin-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8)), linear-gradient(to top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8));
}

.featured-img {
  /*height: 350px;
  width: 250px;*/
  margin: 0 auto;
  border-radius: 15px;
}

@media (min-width: 720px) {
  .featured-img {
    height: 340px;
    width: 255px;
  }
}

/* need to verify for device size */
@media (max-width: 480px) {
  .featured-img {
    height: 290px;
    width: 218px;
  }
}

/* iphoneXS - looks good */
@media (max-width: 375px) {
  .featured-img {
    height: 280px;
    width: 215px;
  }
}

/* need to verify for device size */
@media (max-width: 320px) {
  .featured-img {
    height: 280px;
    width: 215px;
  }
}

.home-img {
  cursor: pointer;
  margin: auto;
  border-radius: 10px;
}

@media (min-width: 720px) {
  .home-img {
    height: 175px;
    width: 125px;
  }
}

@media (max-width: 480px) {
  .home-img {
    height: 125px;
    width: 100px;
  }
}

@media (max-width: 375px) {
  .home-img {
    height: 100px;
    width: 75px;
  }
}

@media (max-width: 320px) {
  .home-img {
    height: 100px;
    width: 70px;
  }
}

.featured-sm-img {
  border-radius: 100px;
}

@media (min-width: 720px) {
  .featured-sm-img {
    height: 125px;
    width: 125px;
  }
}

@media (max-width: 480px) {
  .featured-sm-img {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 375px) {
  .featured-sm-img {
    height: 75px;
    width: 75px;
  }
}

@media (max-width: 320px) {
  .featured-sm-img {
    height: 70px;
    width: 70px;
  }
}

@media only screen and (max-width: 760px) {
  .slick-arrow {
    display: none !important;
  }
}

.home-navigation {
  margin: auto;
}

.home-navigation-wrapper {
  height: auto;
}

.home-navigation-wrapper > .slick-slider > .slick-list > .slick-track {
  margin-left: 0px !important;
}

.search-input {
    padding-right: 0px;
}

.search-input-text {
    color:white !important;
}

.search-text {
    margin-left: 5px;
}

.p-right-0 {
    padding-right: 0;
}

.p-left-0 {
    padding-left: 0;
}

.searchIcon {
    height: 20px;
    cursor: pointer;
}

.mute-icon {
    height: 20px;
    filter: brightness(3);
    margin-top: auto;
    text-align: center;
}

.edit-text {
    padding-left: 10px;
}
.capsule-options {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
    background: white;
    overflow: hidden;
  }

  .capsule-options.scrollable {
    position: sticky;
    background-color: black;
    width: 100%;
  }


  @media (min-width: 375px) {
    .capsule-options {
      font-size:13px;
  }
}

.scrollable {
  overflow: auto !important;
}

.capsule-options-img {
  height: 22px;
  width:auto;
  filter: brightness(100%);
  -webkit-user-select: none;
          user-select: none;
}

.capsule-options-img1 {
  height: 22px;
  width:auto;
  filter: brightness(0);
  -webkit-user-select: none;
          user-select: none;
}

.text-black{
  color: black;
}

.text-white {
  color: white;
}

.capsule-options-img-disable {
  height: 22px;
  width:auto;
  color: grey;
  filter: brightness(75%);
  -webkit-user-select: none;
          user-select: none;
}

@media (max-width: 360px) {
  .capsule-options {
    font-size:10px;
  }
}

.vertical-divider {
    height: 50px;
}

.media-card {
  margin-top: 15px;
  background-color: grey;
}

.chatbot-width {
  width: 90%;
}

.chatbot-standalone {
  height: 100vh;
  background-color: white;
}

.chatbot-standalone-ios {
  height: 90vh;
  background-color: white;
}

.media-images {
  height: 75px;
  width: 75px;
  border-radius: 5px;
  cursor: pointer;
}

.media-videos {
  border-radius: 15px;
  max-height: 75vh;
  max-width: 100%;
}

.media-list {
  padding-top: 15px;
      text-align: left;
      align-self: left;
      margin: 0;
  }

  .media-header {
      padding-bottom: 10px;
  }

  .media-bio {
    text-align: justify;
  }

  .media-option {
    margin-top: 15px;
  }

  .label {
    color: rgb(77, 76, 76);
  }

  #toggler:hover {
      cursor: pointer;
  }

  .media-icons {
    height: 20px;
    padding: 0;
  }

  .option-container {
    padding-left: 0px;
  }

  .icon-container {
    padding-right: 0px;
  }

  .closeButton {
    font-size: 14px;
  }

  .closeButton:hover {
    cursor: pointer;
  }

  /* capsule display - profile image */
  .capsule-pic {
    height: 75px;
    width: 75px;
    float: left;
    margin-right: 10px;
    border-radius: 10px;
  }

  .connect {
    height: 22px;
  }

  .bg-wh {
    background-color: white !important;
  }

#multichat-chat-box {
  background-color: white !important;
}

  #fanbot-chat-widget .fanbot-header {
    display: none !important;
    visibility:hidden !important;
  }

.capsule-pic.no-pic {
  background-color: rgba(255,255,255,0.3);
  height: auto;
  width: auto;
  max-width: 75px;
  max-height: 75px;
  min-width: 65px;
  min-height: 65px;
  margin-right: 10px;
}


  .capsule-pic-mini {
    width: 100px;
    height: 125px;
    border-radius: 10px;
    float:left;
    margin-right: 10px;
  }

  .capsule-header {
    position: relative;
  }

  .capsule-header.linear {
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(11,11,11,0.9248074229691877) 100%);
  }

  .add-card-container {
    text-align:center;
    z-index: 0;
  }

  .add-card-option {
    margin-top: 25px;
    cursor: pointer;
  }

  .add-card-option.create-new {
    margin-bottom: 65px;
    font-size: 20px;
  }

  .add-card-intro {
    margin-bottom: 40px;
    margin-top: 15px;
  }

  .add-card-cancel {
    position:sticky;
    position: -webkit-sticky;
    bottom:0;
    z-index: 5555;
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid grey;
    cursor: pointer;
  }

  .new-group-input {
    display: block;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none !important;
      width: 50%;
      text-align: center;
      align-self: center;
      margin: 0 auto;
      outline-color: transparent;
      outline-style: none;
  }

.form-control:focus {
    outline-color: transparent !important;
    outline-style: none !important;
  }

  .group-create-success {
    max-height: 100px;
    width: auto;
    margin-bottom: 15px;
  }

  .vertical-align {
    text-align: center;
    padding: 0;
  }

  .capsule-view.bottom-view {
    height: calc(var(--vh, 1vh) * 20);
    margin-top: 20px;
    position: relative;
    background-color: white;
    opacity: 0.8;
    color: black;
    text-align: center;
    align-items: center;
  }

  .share-image {
    width:auto;
    max-height: 50px;
  }

  .share-cancel {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    border-top: 1px solid grey;
    cursor: pointer;
  }

  .qr-code {
    box-sizing: content-box !important;
  }

  .option-text {
    white-space: nowrap;
    margin-top: 5px;
    font-weight: bold;
    color: #3399ff;
    -webkit-user-select: none;
            user-select: none;
  }

  .option-text-disable {
    white-space: nowrap;
    margin-top: 5px;
    font-weight: bold;
    color: #C0C0C0;
    -webkit-user-select: none;
            user-select: none;
  }

  .react-viewer-image {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
    transform: none !important;
  }

  .video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    pointer-events: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

.video-background-blur {
  filter: blur(15px);
}

.loader {
  position: absolute;
  top: 45%;
  left: 45%;
}

.draggable {
  height: calc(var(--vh, 1vh) * 35);
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-height: 780x) {
  .content-container {
    height: 54% !important;
}
}

#capsule-view {
  transition: height 0.3s ease-in;
}

.content-container {
  height: 67%;
  overflow-y: auto;
  overflow-x: hidden;
}

.white-arrow {
  filter: brightness(5);
}

.white-arrow.nav-button {
  filter: brightness(10);
}

.capsule-container-max {
  max-height: 100%;
}

.capsule-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capsule-footer-option {
  border-right: 2px solid #78797B;
  border-left: 2px solid #78797B;
}

.selected {
  background: grey !important;
  cursor: pointer;
}

.unselected:hover {
  background: lightgrey !important;
  cursor: pointer;
}

.disable-button {
   cursor: default
}

.overflow-hidden {
  overflow: hidden;
}

.vcard-title {
  color: darkgrey;
}

.share-spacing {
  padding-top: 20px;
}

.share-back-notes {
  font-size: 13px;
}

.share-back-spacing {
  padding-top: 80px;
}

.pos-rel {
  position: relative;
  overflow: hidden;
}

#fanbot-chat-widget .container {
  padding-left: 0px;
  padding-right: 0px;
}

#fanbot-chat-widget .input-wrapper .container {
  padding-left: 0px;
  padding-right: 0px;
}

.share-margin {
  margin-top: 2
}
  .footer-arrow.opened {
    margin-bottom: 5px;
    height: 10px;
  }

  .footer-arrow.closed {
    transform: rotate(180deg);
    height: 10px;
  }

  .col-center {
    display: flex;
    align-items: center;
  }

  .col-center.left {
    text-align: right;
  }


  .col-center.right {
    text-align: left;
  }

  .wrap-container {
    height: 100%;
    overflow: hidden;
  }

  .fullscreen-button {
    position: absolute;
    z-index: 5;
  }

  @media (max-width: 799px) {
    .days-spacing {
      margin-left: -4%;
    }
  }

  @media (min-width: 800px) and (max-width: 999px) {
    .days-spacing {
      margin-left: -2%;
    }
  }

  @media (min-width: 1000px) {
    .days-spacing {
      margin-left: -3%;
    }
  }

  @media {
    .hours-spacing {
      margin-left: 3%;
    }
  }

  @media {
    .minutes-spacing {
      margin-left: 3%;
    }
  }

  .duration-spacing {
    padding-top: 0px;
  }

.hidden {
    visibility: hidden;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-processing .modal-content {
    bottom: 150px;
    min-height: 150px !important;
}

.modal-playback {
    max-height: 75vh !important;
}

.close-video {
    position: absolute;
    right: 5%;
    z-index: 5000;
    cursor: pointer;
}

.video-plyr {
    outline: none !important;
}
.page-not-show {
    position: absolute;
    bottom: 50%;
    text-align: center
}

.ios-frame {
    border: none;

    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 100%;
    overflow:  hidden;
}

.ios-frameElsForAutism {
    border: none;

    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 100%;
    overflow:  hidden;
    background-color: white;
}

.facebook-align {
    text-align: center;
}

.elsWhiteBackground {
    background: white;
}

.blackBackground {
    background: black;
}

.edit-option {
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.preview-option {
    border: 1px solid white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.active {
    opacity: 0.8;
    background-color: white;
    color: black;
}

.header-options {
    padding-top: 15px;
    padding-bottom: 15px;

}

.header-options.preview {
    padding-top: 15px;
    padding-bottom: 10px;
}

.instructions-text {
    text-align: center;
}

@media (max-width: 414px) {
    .last-name {
        margin-top: 10px !important;
     }
  }


.set-background-container {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden !important;
}

.create-capsule-divider {
    width: 100%;
}

.capsule-input {
    -webkit-appearance: none;
            appearance: none;
    text-overflow: ellipsis;
    border-radius: 0;
    background: transparent;
    border: none;
    color: black;
    border-bottom: 1px solid grey;
    outline: none !important;
    align-self: center;
    width: 100%;
    outline-color: transparent;
    outline-style: none;
}

.capsule-input.textarea {
    height: 100%;
    overflow: hidden;
    resize: none;
    white-space: nowrap;
}

.capsule-input.category {
    font-size: 14px;
    margin-top: 10px;
}

.capsule-input::placeholder {
    color: grey;
    font-size: 14px;

}

.capsule-header {
    padding-bottom: 15px;
}

.capsule-header.vcard {
    text-align: center;
    padding-top: 10px;
    font-size: 17px;
}

.vCard-icons {
    max-height: 32px;
    max-width: 32px;
}

.input-row {
    padding-bottom: 15px;
}

.input-row-title {
    padding-bottom: 14px;
    font-size: 16px;
    word-wrap: break-word;
}

.input-row-title1 {
    padding-bottom: 5px;
    font-size: 16px;
    word-wrap: break-word;
}

.website-width {
    width: 96%;
}

.show-more {
    text-align: center;
    font-size: 13px;
}

.show-more.opened {
    padding-bottom: 15px;
}

.connect-button {
    text-align: center;
    border: 1px solid white;
    border-radius: 20px;
    opacity: 0.5;
}

@media (max-width: 575px) {
    .connect-button {
        margin-top: 20px;
    }
  }

  @media (max-width: 575px) {
    .vCard-icons {
        margin-bottom: 10px;
    }
  }

  .reorder-button {
    background: white;
    background: linear-gradient(white, #293f50);
    padding-left: 15px;
    border-radius: 20px;
    width: 75%;
    opacity: 0.5;
    margin: auto;
    cursor: pointer;
    text-align:center;
  }

  .create-card {
    border: 1px solid white;
    border-radius: 20px;
    font-size: 20px;
    width: 75%;
    margin: 30px auto;
  }

  .save-button {
      text-align: right;
  }

  .vCard-icons.add-remove {
    max-height: 25px;
    max-width: 25px;
    padding-right: 15px;
  }

  .no-padding {
    padding-left: 15px;
  }

  .media-image {
    height: 60px;
    width: auto;
      cursor: pointer;
      border-radius: 10px;
  }

  .media-list-image {
    height: 100px;
    width: 100px;
      cursor: pointer;
      border-radius: 10px;
  }
  @media (max-width: 320px) {

  .media-list-image {
    height: 75px;
    width: 75px;
      cursor: pointer;
      border-radius: 10px;
  }
}

  .media-image.document {
      height: 60px;
      width: auto;
  }

  .photos {
      text-align: center;
  }

  .media-option {
      cursor: pointer;
  }

  .small-text {
      font-size: 12px;
  }

  .settings-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .profile-divider {
      width: 100%;
  }

  .delete-button {
      margin-top: 15px;
      cursor: pointer;
      color: red;
  }

  .bottom-button {
    border: 1px solid white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .manage-icons {
      max-height: 25px;
      max-width: 25px;
      cursor: pointer;
  }


  .manage-icons.add-remove {
    max-height: 25px;
    max-width: 25px;
    margin-right: 15px;
  }

  .edit-menu-option {
      margin-right: 30px;
      text-align: center;
      cursor: pointer;
  }

  .social-header {
      padding-bottom: 15px;
  }

  .mr-15 {
      margin-right: 15px;
  }

  .mb-10 {
      margin-bottom: 15px;
  }

  .capsules-container {
    height: calc(var(--vh, 1vh) * 65);
    overflow: auto;
    overflow-x: hidden;

  }

.capsules-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.capsules-container::-webkit-scrollbar:vertical {
    width: 12px;
}

.capsules-container::-webkit-scrollbar:horizontal {
    height: 12px;
}

.capsules-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.capsules-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.color-grey {
    color: rgb(129, 128, 128);
    border-bottom: 1px solid grey
}

.slash {
    color: darkgrey;
}

.section-card {
    background: rgba(255,255,255,0.1);

    filter: blur('5px');
    border-radius: 15px;
    color: white !important;
}

.profile-image {
    height: 35px;
    width: auto;
    float: left;
}

.profile-image.password {
    height: 40px;
}

.profile-row {
    margin-left: 5px;
    margin-top: 20px;
}

.profile-card {
    border-radius: 15px;
    color: grey;
    background-color: white;
}

.radius {
    border-radius: 10px;
}

/* profile icon in Edit and Profile-profile section*/
.profile-photo-icon {
    max-width: 120px;
    height: auto;
    margin-left: -10px;;
    margin-top: -10px;
}

/* Profile image in Edit and Profile-profile section*/
.profile-edit-photo {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  margin-left: 2px;
  margin-top: 6px;
}

/* capsule icon*/
.capsule-photo-icon {
    max-width: auto;
    height: 190px;
    margin-top: -20px;
}

/* capsule image size in Edit */
.capsule-edit-photo {
  height: 140px;
  width: auto;
  margin: 0 auto;
}

/* capsule image size in Profile User section via logic in Edit*/
.profile-section-capsule-img {
    height: 160px;
    width: auto;
    margin: 0 auto;
}

.profile-audio-docs-icon {
    max-width: 100px;
    height: auto;
}

@media (max-width: 769px) {
    .profile-video {
        max-height: 40px;
    }
}

@media (min-width: 769px) {
    .profile-video {
        max-height: 60px;
    }
}

@media (max-width: 575px) {
    .profile-video {
        max-height: 60px;
    }
}


.profile-closed {
    background: white;
    color: black;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;

}

.down-arrow {
    transform: rotate(270deg);
    height: 20px;
}

.mt-10 {
    margin-top:20px;
}

.color-black {
    color: black !important;
}

.button-up {
    transform: rotate(180deg);
}

.section-button {
    height: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.create-title {
    font-size:  15px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 30px;
    font-weight: bold;
}

.button-padding {
    padding-top:100px;
    padding-bottom: 50px;
}

.create-button {
    border: 1px solid;
    border-radius: 100px;
    width: 150px;
}

.create-button-coverURL {
    border: 1px solid;
    border-radius: 100px;
    width: 160px;
}

.create-button.set-group {
    margin-top: 15px;
    width: auto;
}

.create-panel {
    margin-left: 50px;
    margin-right: 50px;
}

.pb-15 {
    padding-bottom: 15px;
  }

  .back-button {
      height: 20px;
  }

.trash-icon {
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.main-section-button {
    transform: rotate(270deg);
    height: 20px;
}

.main-section-button.opened {
    transform: rotate(90deg);
}

.section-icon {
    height: 20px;
    margin-right: 10px;
}

.section-icon.notes {
    height: 15px;
}

.vcard-section {
    margin-bottom: 10px;
}

.capsule-section {
    margin-top: 20px;
    padding-right: 20px;
}

.link-icon {
    height: 50px;
}

.button-row {
    margin-top: 10px;
    margin-bottom: 15px;
}

.row-height-short {
    margin-top: 0px;
    margin-bottom: 0px;
}

.button-row-set {
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.button-option-left {
    margin-left: 60px;
}

.button-option-right {
    margin-left: -20px;
}

.button-option-left-text {
    margin-left: 55px;
    margin-right: -5px;
}

.button-option-right-text {
    margin-left: 10px;
    margin-right: 40px;
}

.button-center {
    margin-left: 42%;
    width: 60px;
    padding-bottom: 10px;
}

.button-left {
    margin-top: -10px;
    margin-right: 30%;
    margin-bottom: 30px;
}

.button-close {
    margin-top: 30px;
    margin-left: 32%;
    margin-bottom: 50px;
}

.row-spacing {
    margin-top: 40px;
    margin-left: 10%;
}

.modal-headers {
    color: grey;
    font-size: 17px;
    border: none;
}

.verify-modal-background {
    background-color: black;
    color: white;
}

.create-button.btn-sm {
    margin-top: 15px;
}

.btn-deleteMe {
    background-color: grey;
    padding-top: 2px;
    padding-bottom: 2px;
    color: black;
    width: auto;
    margin-bottom: 10px;
}

.select-file {
    margin-top: 5px;
    color: #4580fc;
    font-weight: 400;
    cursor: pointer;
}

@media (max-width: 1023px) {
  .type-here {
      font-size: 15px;
      margin-left: 5px;
  }
}

@media (min-width: 1024px) {
  .type-here {
      font-size: 15px;
      margin-left: 0px;
  }
}

.option-icon {
    height: 80px;
}

.url-text-color {
    color: black;
}

.group-edit-container {
    max-height:300px;
    overflow-x: hidden;
}

@media (max-width: 400px) { /*check keith's phone, adjust for larger phones*/
  .verify-users-container {
      max-height: 520px;
      overflow-x: hidden;
  }
}

@media (min-width: 401px) {
  .verify-users-container {
      max-height: 520px;
      overflow-x: hidden;
  }
}

.category {
    text-overflow: ellipsis;
}

.modal-headers.groups {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.url-options {
    margin-right: -100px;
}

.upload-inprogress {
    padding-bottom: 15px;
}

.profile-photo-vcard {
    width: 100px;
}

.capsule-input.groups {
    width: 100%;
    text-overflow: ellipsis;
}

.play-button {
    height: 60px;
    width: 60px;
    background-color: rgba(0,0,0,0.3);
}

.media-status {
    text-align: center;
}

.error-msg {
    color: red;
    font-size: 12px;
}

@media (max-width: 1023px) {
    .remove-group{
      margin-leftt: -50px;
    }
}

.group-switcher {
    border: 1px solid black;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.group-switcher.private {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: none;
}

.group-active {
    color: white;
    background: grey;
}

.delete {
    color: red;
}

.color-white {
    color: white;
}

.share-group-copied {
    font-size: 18px;
    padding-left: 10px;
    margin-top: =20px;
    width: auto;
    height: 50px;
    display: inline-block;
}

.sub-groups {
    font-size: 14px;
    padding-left: 25px;
    color: grey;
}

.subgroup-button {
    width: 25px;
}

.subgroup-button.opened {
    transform: rotate(180deg);
}

.path-arrow {
    transform: rotate(270deg);
    height: 7px;
}

.back-to-top-button {
    transform: rotate(180deg);
    height: 9px;
    width: 15px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 2px;
}

.type-select {
    padding-right: 0;
}

.add-field-icon {
    cursor: pointer;
}

.add-field-icon.plus {
    height: 20px;
}

.add-field-icon.minus {
    height: 5px;
}

.word-break {
    word-break: normal;
}

.modal-copy {
    max-width: 140px;
    margin-left: auto !important;
    margin-right: auto !important;
    top: 50vh;
}

.date-field {
    width: 100%;
}



  @media (max-width: 2560px) {
    .modal-copy {
        top: 450px;
    }
  }

  @media (max-width: 1440px) {
    .modal-copy {
        top: 60vh;
    }
  }

  @media (max-width: 1024px) {
    .modal-copy {
        top: 55vh;
    }

  }

  @media (max-width: 768px) {
    .modal-copy {
        top: 55vh;
    }
  }

  @media (max-width: 425px) {
    .modal-copy {
        top: 55vh;
    }
  }

  @media (max-width: 375px) {
    .modal-copy {
        top: 50vh;
     }
  }

  @media (max-width: 320px) {
    .modal-copy {
        top: 62vh;
     }
  }

  .full-height-modal {
      height: 100%;
  }

  .full-height-modal .modal-content {
      height: 100% !important;
  }

  #name, #category {
      color: white !important;
  }

.group-list-divider {
    height: 25px;
}

.divider-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -2px;
    margin-left: 10px;
}

.group-list {
    position: relative;
    -webkit-overflow-scrolling: touch;
}

.group-spacing {
    margin-top: 22px;
}

@media(max-width: 1023) {
  .group-text-spacing {
      margin-left: 0px;
  }
}

@media(min-width: 1024) {
  .group-text-spacing {
      margin-left: 5px;
  }
}

.group-text-center {
    margin-left: -25%;
}

@media(max-width: 1023px) {
  .group-text-center {
      margin-left: 28%;
  }
}
@media(min-width: 1024px) {
  .group-text-center {
      margin-left: 35%;
  }
}

.group-element {
    margin-bottom: 10px;
    word-break: break-word;
    overflow-x: hidden;
}

.groups-width {
    width: 100%;
}

@media (max-width: 1023px) {
  .left-banned-text {
      margin-left: 0px;
      margin-bottom: -3px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-banned-text {
      margin-left: 0px;
      margin-bottom: -3px;
      color: black;
  }
}

@media (max-width: 1023px) {
  .left-banned-box-spacing {
      margin-top: -8px;
      margin-left: -20px;
  }
}

@media (min-width: 1024px) {
  .left-banned-box-spacing {
      margin-top: -8px;
  }
}

@media (max-width: 1023px) {
    .left-value-verified-line1 {
        margin-left: -4%;
        margin-bottom: -3px;
        color: black;
    }
}

@media (max-width: 1023px) {
    .left-value {
        margin-left: 0%;
        margin-bottom: 5px;
        color: black;
    }
}

@media (max-width: 1023px) {
  .left-value-verified2 {
      margin-left: -1%;
      margin-bottom: 0px;
      font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .left-value-verified2 {
      margin-left: 0%;
      margin-bottom: 0px;
      font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .left-value-verified1 {
      margin-left: -1%;
      margin-bottom: 0px;
      font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .left-value-verified1 {
      margin-left: 0%;
      margin-bottom: -16px;
      font-size: 18px;
  }
}

@media (max-width: 1023px) {
  .left-value-verified {
      margin-left: -4%;
      margin-bottom: -14px;
      color: black;
  }
}

@media (max-width: 1023px) {
  .left-value-verified-secondary {
      margin-left: -4%;
      margin-bottom: 15px;
      color: black;
  }
}

@media (min-width: 1024px) {
    .left-value {
        margin-left: 5%;
        margin-bottom: 5px;
        color: black;
    }
}

@media (min-width: 1024px) {
  .left-value-verified {
      margin-left: 0%;
      margin-bottom: -16px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-value-verified-line1 {
      margin-left: 0%;
      margin-bottom: -3px;
      color: black;
  }
}

@media (min-width: 1024px) {
  .left-value-verified-secondary {
      margin-left: 0%;
      margin-bottom: 15px;
      color: black;
  }
}

@media (min-width: 1024px) {
    .left-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-left:20px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-right: -150px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist{
        margin-bottom: 0px;
        padding-top: 5px;
        color: black;
        margin-right: -180px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist1{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-left: -35px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist1{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-left: -85px;
    }
}

@media (min-width: 1024px) {
    .right-grouplist2{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-right: 0px;
    }
}

@media (max-width: 1023px) {
    .right-grouplist2{
      margin-bottom: 0px;
      padding-top: 5px;
      color: black;
      margin-right: 0px;
    }
}

@media (max-width: 1023px) {
    .right-value {
        margin-top: 5px;
        margin-right: -60%;
        margin-bottom: 5px;
    }
}

@media (min-width: 1024px) {
    .right-value {
        margin-top: 5px;
        margin-right: -40%;
        margin-bottom: 5px;
    }
}

@media (max-width: 1023px) {
    .right-value-verified2 {
        margin-top: 3px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified2 {
        margin-top: 5px;
        margin-right: -37%;
    }
}

@media (max-width: 1023px) {
    .right-value-verified1 {
        margin-top: 7px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified1 {
        margin-top: 7.3px;
        margin-right: -37%;
        margin-bottom: 4px;
    }
}

@media (max-width: 1023px) {
    .right-value-verified {
        margin-top: 7px;
        margin-right: -65%;
        margin-bottom: 0px;
    }
}

@media (min-width: 1024px) {
    .right-value-verified {
        margin-top: 7.3px;
        margin-right: -37%;
        margin-bottom: 4px;
    }
}

@media (max-width: 1023px) {
    .right-value1 {
        padding-bottom: 20px;
        margin-left: -7%;
    }
}

@media (min-width: 1024px) {
    .right-value1 {
        margin-top: 5px;
        margin-right: -40%;
    }
}

.status-italics {
    font-style: italic;
    font-size: 16px;
}

.gray {
    color: gray;
}

.count {
    font-size: 15px;
}

.header-padding {
    padding-top: 50px;
}

.filter-line {
    font-size: 20px;
    font-weight: bold;
}

.title-line {
    font-size: 19px;
    font-weight: bold;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.second-title-line {
    font-size: 17px;
    font-weight: bold;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.reorder-title-line {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
}

.reorder-title {
    margin-right: -1px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 3px; /* here only to align bottom of text with title-line Features/Favorites due to different font size */
}

.group-title {
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 1023px) {
  .group-right-title {
      font-size: 16px;
      margin-top: 2px;
  }
}

@media (min-width: 1024px) {
  .group-right-title {
      font-size: 16px;
      padding-top: 5px;
  }
}

@media (max-width: 1023px) {
  .main-group-seeAll-title {
      font-size: 16px;
      margin-left: -100px;
      padding-top: 0px;
  }
}

@media (min-width: 1024px) {
  .main-group-seeAll-title {
      font-size: 16px;
      margin-left: -100px;
      padding-top: 5px;
  }
}

@media (max-width: 1023px) {
  .manage-favorites-settings-icon {
        position: absolute;
        right: 4%;
        padding-top: 2px;
        height: 20px;
        width: 25px;
        cursor: pointer;
        border-radius: 5px;
        filter: grayscale(100%);
        opacity: 0.8;
        background: white;
  }
}

@media (min-width: 1024px) {
  .manage-favorites-settings-icon {
        position: absolute;
        right: 3%;
        padding-top: 2px;
        padding-bottom: 2px;
        height: 25px;
        width: 36px;
        cursor: pointer;
        border-radius: 5px;
        filter: grayscale(100%);
        opacity: 0.8;
        background: white;
  }
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-overflow {
    overflow-x: hidden;
}

/*.group-container {
    background-color: white;
    color: black;
} */

.subgroup-arrow {
    transform: rotate(180deg);
    height: 15px;
    margin-right: 5px;
}

.group-search-input {

    -webkit-appearance: none;

            appearance: none;
    width: 100%;
    border-radius: 0;
    background: transparent;
    color: white;
    border: none;
    outline: none !important;
    align-self: center;
    outline-color: transparent;
    outline-style: none;
  }

.x-icon {
    position: absolute;
    right: 0;
    bottom: 53px;
    cursor: pointer;
    z-index: 1;

    height: calc(var(--vh, 1vh) * 3);
}

/* reorder-cancel-button (all) and reorder-save-button (all) need to modify button layout area (persistent) so can move the buttons
to fix UI layout - right now when moved, cancel button doesn't trigger */
/* Also right now these (all) are fit to my mac and my iphone XS so need to check/update for other device sizes */
@media (max-width: 1023px) {
  .reorder-cancel-button {
    margin-left: 80%;
    width: 80px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .reorder-cancel-button {
    margin-left: 80%;
    width: 120px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (max-width: 1023px) {
  .reorder-save-button {
    margin-right: -70%;
    width: 80px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .reorder-save-button {
    margin-right: 40%;
    width: 120px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid;
  }
}

@media (min-width: 1024px) {
  .title-align {
    margin-left: 250px;
  }
}

@media (max-width: 1023px) {
  .title-align-reorder-left {
    margin-left: 0px;
  }
}

@media (min-width: 1024px) {
  .title-align-reorder-left {
    margin-left: 100px;
  }
}

@media (max-width: 1023px) {
  .circle-green {
    height: 20px;
    width: 20px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-green {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: green;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-blue {
    height: 20px;
    width: 20px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-blue {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: blue;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-yellow {
    height: 20px;
    width: 20px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-yellow {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: yellow;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-red {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-red {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: red;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle-filled {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle-filled {
      height: 20px;
      width: 20px;
      margin-top: -50px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
  }
}

@media (max-width: 1023px) {
  .circle {
    height: 20px;
    width: 20px;
    border: solid 1px;
    border-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .circle {
    height: 20px;
    width: 20px;
    margin-top: -50px;
    border: solid 1px;
    border-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

.divider-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -2px;
    margin-left: 10px;
}

@media(max-width: 1023px) {
  .divider-circle1 {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 4px;
      margin-left: 0px;
  }
}

@media(max-width: 1023px) {
  .divider-circle2 {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 11px;
      margin-left: 0px;
  }
}

@media(min-width: 1024px) {
  .divider-circle1 {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-top: -2px;
      margin-left: 10px;
  }
}

@media(min-width: 1024px) {
  .divider-circle2 {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-top: -4px;
      margin-left: 10px;
  }
}

.search {
    height: 20px;
}

.poptions {
    pointer-events: none;
}

.move-right {
    margin-left: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 10px;
}

@media (max-width: 1023px) {
  .publish-background {
      background-color: black;
      margin-top: 45px;
  }
}

@media (min-width: 1024px) {
  .publish-background {
      background-color: black;
      margin-top: 25px;
      margin-left: 12%;
  }
}

.options {
    position: sticky;
    bottom: 15px;
    right: 15px;
    width: 100%;
    z-index: 120000;
}

.options-open {
    position: sticky;
    bottom: 15px;
    right: 15px;
    width: 100%;
}

.stop-scroll {
    overflow: hidden;
}

.blurred {
    filter: blur(5px)
}

.group-menu-icon {
    width: 37px;
    height: 37px;
    margin: 5px;
}

.letter-line {
    background: linear-gradient(to right,  rgba(198,198,198,1) 0%,rgba(252,252,252,0) 97%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6c6c6', endColorstr='#00fcfcfc',GradientType=1 );
}

.pl-15 {
    padding-left: 15px;
}

.fixed-header-height {
    height: 50px;
}

.home-navigation {
    position: relative;
}

.favorite-button {
    position:absolute;
    right: 15px;
    cursor: pointer;
    width: 20px;
    z-index: 5000;
}

.favorite-button.plus {
    width: 30px;
    z-index: 5000;
}

@media (min-width: 320px) {
    .favorite-button {
        top: -5px;
        right: 0px;
    }
}

@media (min-width: 375px) {
    .favorite-button {
        top: -5px;
        right: 7px;
    }
}

@media (min-width: 768px) {
    .favorite-button {
        right: 20px;
    }
}

@media (min-width: 1024px) {
    .favorite-button {
        right: 5px;
    }
}

@media (min-width: 1440px) {
    .favorite-button {
        right: 15px;
    }
}

.profile-icon {
    height: 30px;
    width: 30px;
    border-radius: 25px;
}

.public-button {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    z-index: 1000;
}

.option-img.plus {
    transform: rotate(45deg);
}

.option-img.minus {
    transform: rotate(90deg);
    margin-top: 7px;
    margin-right: 7px;
}

@media (max-width: 1023px) {
  .filter-page {
      font-size: 16px;
      margin-right: -240px;
  }
}

@media (max-width: 1023px) {
  .group-circle {
      margin-right: 225px;
  }
}

@media (max-width: 1023px) {
  .group-circle-featured-filter-line {
      margin-right: 0px;
  }
}

@media (min-width: 1024px) {
  .group-circle-featured-filter-line {
      margin-right: 250px;
  }
}

@media (max-width: 1023px) {
  .reorder-favorites-right {
      margin-left: 30%;
  }
}

@media (min-width: 1024px) {
  .reorder-favorites-right {
      margin-right: 250px;
  }
}

@media (min-width: 1024px) {
  .filter-page {
      font-size: 20px;
      margin-left: 236px;
  }
}

@media (min-width: 1024px) {
  .group-circle {
      margin-right: 250px;
  }
}

.lightgrey {
    color: #D3D3D3;
}

.blue {
    color: blue;
}

.lighterblue {
    color: #0000FF;
    font-size: 18px;
}

.account-left {
    margin-left: -80px;
}

.category {
    font-size: 12px;
}

.capsule-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media(max-width: 1023px) {
  .black {
      color: black;
      font-size: 16px;
      margin-left: 2px;
  }
}

@media(min-width: 1024px) {
  .black {
      color: black;
      font-size: 18px;
      margin-left: 2px;
  }
}

.logo-container {
   padding-top: 25px;
   text-align: center;
   padding-bottom: 20%;
}

.logo-container.email-container {
    padding-bottom: 25px;
}

.logo-container.sign-up-container {
    padding-bottom: 10%;
}

.sign-up-input-container {
    padding-bottom: 15px;
}

.sign-up-input {
    padding-bottom: 20px;
}

.logo {
    max-height: 200px;
    width: auto;
}

.success-container {
    margin-top: 60px;
}

.logo.signup {
    max-height: 130px;
    width: auto;
}

.logo.email-confirm {
    max-height: 100%;
    width: auto;
    margin: 10px;
}

.logo-container.success {
    margin-top: 45px;
}

.back {
    max-height: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.logo.change-mail {
    max-height: 40%;
    width: auto;
    margin: 10px;
}

.confirm-text {
    font-size: 35px;
}

.next.success {
    padding-top: 20px;
    margin-bottom: 0;
    cursor: pointer;
}

.login-buttons {
    position: absolute;
  top: calc(var(--vh, 1vh) * 80);
  left: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.login-input {
    -webkit-appearance: none;
            appearance: none;
    background-color: rgba(60,117,239, 0.25);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    outline: none !important;
    text-align: center;
    margin: 0 auto;
    border: 3px solid #4273F0;
    border-radius: 5px;
    width: 100%;
    outline-color: transparent;
    outline-style: none;

}

.selected-dial-code {
    color: white;
}

.login-button {
    margin-top: 15px;
    color: blue;
    width: 100px;
    border-color: #0E60EC;
    border-radius: 20px;
}

.terms {
    color: white !important;
    text-decoration: underline;
}

.terms:hover {
    color: grey !important;
    text-decoration: underline;
}

@media (max-width: 1023px) {
  .signup-spacing {
      margin-top: 50px;
  }
}

@media (min-width: 1024px) {
  .signup-spacing {
      margin-top: 0px;
  }
}

.logo-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 320px) and (max-width: 339px) {
    .logo-center {
        width: 93%;
    }
}

@media only screen and (min-width: 340px) and (max-width: 359px) {
    .logo-center {
        width: 86%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 385px) {
    .logo-center {
        width: 80%;
    }
}

@media only screen and (min-width: 386px) and (max-width: 428px) {
    .logo-center {
        width: 74%;
    }
}

@media only screen and (min-width: 429px) and (max-width: 440px) {
    .logo-center {
        width: 65%;
    }
}

@media only screen and (min-width: 441px) and (max-width: 480px) {
    .logo-center {
      width: 63%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .logo-center {
      width: 56%;
    }
}

@media only screen and (min-width: 768px) {
  .logo-center {
      width: 41%;
  }
}

.invalid-input {
    border-color: red;
}

.login-input::placeholder {
    color: white;
    text-align: center;
}

.phone-input {
    padding-left: 0px !important;
}

.flag-container {
    color: black;
}

.sign-up {
    cursor: pointer;
}

.sign-up-disabled {
    opacity: 0.4;
}

.logo-effect {
    position: absolute;
    top: -57px;
    left: -51px;
    max-height: 72%;
    width: auto
}

.top-text {
    font-size: 30px;
    letter-spacing: 15px;
}

.bottom-text {
    margin-top: 15px;
    font-size: 20px;
}

.login {
    cursor: pointer;
    padding-top: 15px;
}

.login.change-email {
    padding-top: 0;
}

.terms-container {
    padding-top: 30px;
}

.email-validated {
    margin-top: 50%;
}

.next {
    margin-bottom: 50px;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
     -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
 -webkit-user-select: none; /* Chrome, Safari, and Opera */
 -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 100000;
}

.footer.edit-footer {
  padding-top: 10px;
  background-color: rgba(0,0,0);
}

.footer-navigation {
  text-align: center !important;
}

.footer-navigation-img {
  height: 45px;
  width: auto;
}

.footer-navigation-img.contacts {
  height: 25px;
}

.footer-navigation-img.explore {
  height: 25px;

}

.contact-label {
  font-size: 11px;
  margin-top: 5px;
  letter-spacing: 1px;
  opacity: 0.8;
}

/*For mobile devices*/
/* @media (min-width: 0 px) and (max-width: 359px){
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 30px;
		left: 36%;
	}
} */

@media only screen and (max-width: 374px) {
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 44px;
		width: 140px;
		left: 50%;
		margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (max-height: 453px) {
	/*purple - wrap in capsule - iphone SE & iphone 8 */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 44px;
		width: 140px;
		left: 50%;
		margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 454px) {
	/* navy blue - full wrap - iphone SE & iphone 8 */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 455px) {
	/* red - wrap in capsule - iphone X */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 14%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 580px) {
	/* blue - full wrap - iphone X, Huawei Mate 20, iPhone 11 Pro */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 376px) and (max-height: 480px) {
	/* adding because turquoise falls into this undefined area*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 8%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (min-width: 376px) and (max-width: 410px) and (min-height: 481px) {
	/* adding because turquoise falls into this undefined area - max height less than 514 iPHone 8 Plus can adjust lower if needed*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 13%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 411px) and (max-height: 480px) {
	/*this is added to allow iphone 8 plus viewport to have the buy button lowered.*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 7%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 411px) and (min-height: 481px) and (max-height: 690px) {
	/*this is added because of the iphone 8 plus (included above) to separate vuewport height of iphone 8plus from iphone 11 types.*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 10%;
			width: 190px;
			left: 50%;
			margin-left: -95px;
	}
}

@media only screen and (min-width: 411px) and (min-height: 691px) {
	/*created for phones taller than ihpone 11 types */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12.6%;
			width: 260px;
			left: 50%;
			margin-left: -130px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (max-height: 600px) {
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 5%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

/*@media only screen and (min-width: 768px) and (max-width: 1200px) and (min-height: 601px){ */
@media only screen and (min-width: 768px) and (max-width: 1099px) and (min-height: 601px){
	/* find device that might fit in this range to try it. Revised to max-width:1099, not sure what the 601 is for - check css */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 13%;
			width: 240px;
			left: 50%;
			margin-left: -120px;
	}
}

/*@media only screen and (min-width: 1201px) {*/
@media only screen and (min-width: 1100px) and (max-height: 750px) {
/*added max-height based on estimated Attila desktop share wrap in capsule, est at 804px so selecting 750, below larger buy button-need to check all devices */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 50px;
		width: 240px;
		left: 50%;
		margin-left: -120px;
	}
}

/*@media only screen and (min-width: 1201px) {*/
@media only screen and (min-width: 1100px) and (min-height: 751px) {
/*added max-height based on estimated Attila desktop share wrap in capsule */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 12%;
		width: 260px;
		left: 50%;
		margin-left: -130px;
	}
}

.viewport-info-top {
	position: absolute;
	top: 25%;
}

.prevent-zoom {
	touch-action: manipulation;
}

.wrap {
	overflow:hidden;
}

.flip-area {
	position: absolute;
	max-width: 650px;
	text-align: center;
}

.z-index {
	z-index: 1000;
}

.transition {
	transition: transform 0.5s;
}

.notransition {
	transition: none !important;
}
.bottom-area {
	height: 500px;
	top: 500px;
	background-color: black;
	max-width: 400px;
}

/*).video-mute {
		position: absolute;
		top: 60%;
		right: 5%;
		z-index: 5000;
		cursor: pointer;
		border-radius: 2px;
}*/

.volume-icon {
		height: 22px;
		width: auto;
}

.language-selector {
	position: absolute;
	right: 3%;
	height: 60px;
	cursor: pointer;
	border-radius: 15px;
}

.switchDisplay-selector {
	position: absolute;
	top: 12px;
	right: 21%;
	height: 28px;
	cursor: pointer;
	border-radius: 0px;
	filter: grayscale(100%);
	opacity: 0.6;
}

.slider-buttons {
	position: absolute;
	top: 65%;
	right: 4.5% !important;
	filter: brightness(8);
}

@media only screen and (max-width: 959px) {
	.video-buttons {
		position: absolute;
		top: 58%;
		right: 9% !important;
		filter: brightness(5);
	}
}

@media only screen and (min-width: 960px) {
	.video-buttons {
		position: absolute;
		top: 58.5%;
		right: 5.5% !important;
		filter: brightness(5);
	}
}

.right-slider {
	height: 10px;
	width: auto;
	transform: rotate(90deg);
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.left-slider {
	height: 10px;
	width: auto;
	transform: rotate(-90deg);
}

.wrap-video {
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
	pointer-events: all !important;
	border-radius: 0px;
	background-color: black !important;
	cursor: default !important;

}

.secondary-info-container {
	position: absolute;
	top:75%;
	left: 50%;
	transform:translate(-50%,-20%);
	margin: auto;
}

.info-container {
	position: absolute;
	top:70%;
	left: 50%;
	transform:translate(-50%,-30%);
	margin: auto;
}

.float-frame {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 50%;
		transform: translateX(-50%);
	visibility: hidden;
	z-index: 2500;
}

.frame-visible {

		visibility: visible;

}

.wrapper {
	height: 100%;
	width: 100%;
}

.info-center {
	margin: auto;
	text-align: center;
	width: 250px;
}

.info-bg {
	background: linear-gradient(90deg, rgba(0,0,6,0) 0%, rgba(0,0,0,0.7035189075630253) 29%, rgba(0,0,0,0.6951155462184874) 67%, rgba(0,0,0,0) 100%);
}

.clickable {
		position: relative;
		z-index: 500;
}

.create-wrap-container {
	height: 500px;
	max-width: 400px;
}

.hide-frame-button {
	position: absolute;
	height: 25px;
	width: 25px;
	right: 20px;
	top: 5px;
	text-align: center;
	z-index: 2500;
	background-color: rgba(0, 0, 0, 0.37);
	border-radius: 25px;
	cursor: pointer;
}

.bg-black {
	background-color: black;
}

.wrap-area {
	z-index: 1500;
	pointer-events: none;
}

.half-wrap-container {
	position: relative;
	height: 250px;
}

#page {
	transform-origin: center left;
	position:absolute;
	height: 600px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#videopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#partialvideopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#imagepage {
	transform-origin: center left;
	position:absolute;
	height: auto;
	width: auto;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-color: black !important;
	left: 0;
	right: 0;
	background-size: auto 100%;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#partialimagepage {
	transform-origin: center left;
	position:absolute;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-color: black !important;
	left: 0;
	right: 0;
	background-size: auto 100%;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
}

.invisible {
	visibility: hidden;
}

#fullvideopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100vw;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	-webkit-user-select: none;
    -o-user-select: none;
	user-select: none;
	z-index: 500;
}

.video-background {
	object-fit: contain !important;
}

#fullvideopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#videopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#partialvideopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#imagepage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#partialimagepage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#page.flipped{

	transform: perspective(3000px) rotateY(-90deg);

}

.hidden {
	visibility: hidden;
}

/*global styles*/

@font-face {
    font-family: 'BigNoodleTitlingOpaque';
    src: url(/static/media/big_noodle_titling_oblique.52629e48.ttf);
}

@font-face {
    font-family: 'RockwellExtraBold';
    src: url(/static/media/rockb.b302cecc.ttf);
}

@font-face {
    font-family: 'RockwellBold';
    src: url(/static/media/ROCK.fcca3a4a.TTF);
}

@font-face {
    font-family: 'Impact';
    src: url(/static/media/impact.8fc622c3.ttf);
}

@font-face {
    font-family: 'Serif';
    src: url(/static/media/PTF55F.3ad858f1.ttf);
}

html, body {
    height: 100vh;
}

.new-line {
    margin-top: -60px;
}

.page-container {
    height: 100%;
    max-width: 100%;
}

.page-container1 {
    position: relative;
    text-align: center;
}

.product-wrapper {
    display: flex;
    align-items: center;
}

.product-wrapperColumn {
    display: flex;
    flex-direction: column;
}

body {
    /*touch-action: manipulation;*/
}

/*@media only screen and (device-width: 414px) and(device-height: 896px) and(-webkit-device-pixel-ratio: 3) and(orientation: portrait) {
    .stage {
        margin-top:48px
    }
}*/

/*@media only screen and (device-width: 375px) and(device-height: 812px) and(-webkit-device-pixel-ratio: 3) and(orientation: portrait) {
    .stage {
        margin-top:48px
    }
}*/

@media only screen and (max-width: 320px) and (max-height: 350px) {
    /* sea green #00FFAA*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one sea green 320*/

    .page-one-background {
        height: auto%;
        width: 82%;
        left: 50%;
        margin-left: -41%;
        top: 23%;
        position: absolute;
    }

    .logo-one {
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left: 20px;
        padding-left: 4%;
        padding-right: -40%;
        height: 50px;
        width: 90px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 52%;
        left: -2%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px; /*if want text higher, increase the positive number */
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 2px;
        /*color: 	#00FFAA; /*sea green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two sea green 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 4%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three sea green 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four sea green 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five sea green 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 550px;
        padding-left: 4%;
        margin-right: 2%;
        margin-top: -20%;
        padding-top: -10px;
        margin-left: 6%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: -3px;
        line-height: 1.0;
        padding-right: 5px;
        padding-left: 5px;
    }

    /*page six sea green 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 1%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;
    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven sea green 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 28%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: -25px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -30px;
      margin-right: 0px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight sea green 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4.4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine sea green 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -3%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten sea green 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 17px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven sea green 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 500px;
        height: auto;
        padding-left: 20px;
        padding-top: -15px;
        margin-top: -35px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 0.9;
    }

    /*page twelve sea green 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-left: 260%;
        margin-bottom: 2%;
    }
}

@media only screen and (max-width: 320px) and (min-height: 351px) and (max-height: 470px) {
    /* NAVY BLUE #000080 */
    /*iphone 5s - full wrap*/
    /* not adding min-width because iPhone SE min-height is larger than 453. if device exists, then can add*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one navy blue*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 1%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        margin-top: 3px;
        margin-left: -9px;
        /*color: #000080; /*navy blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two navy blue*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three navy blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four navy blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five navy blue*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -12%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six navy blue*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven navy blue*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -20px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight navy blue*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine navy blue*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -7px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten navy blue*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven navy blue*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve navy blue*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (max-width: 320px) and (min-height: 471px) and (max-height: 515px) {
    /* light yellow 320 #fffec8*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one light yellow 320*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 7%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        left: 89%;
        top: 70%;
        line-height: 0.8;
        /*color: #fffec8; /*light yellow DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light yellow 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three light yellow 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four light yellow 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five light yellow 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -5%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six light yellow 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;
    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven light yellow 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -5%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight light yellow 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light yellow 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -7px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten light yellow 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light yellow 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: 12%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve light yellow 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (max-width: 320px) and (min-height: 516px) {
    /*water blue 320 #DBF3FA*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one water blue 320*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 13%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        left: 89%;
        top: 70%;
        line-height: 0.8;
        /*color: #DBF3FA; /*water blue 320 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two water blue 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three water blue 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four water blue 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five water blue 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -5%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six water blue 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven water blue 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -1%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight water blue 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine water blue 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -3px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten water blue 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven water blue 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: 30%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve water blue 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 321px) and (max-width: 345px) and (max-height: 450px) {
    /*wine #4E0707*/
    /* due to iPhone SE, all pages are the same as red (sb) except pg 1, update later*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one wine*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -10%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 27px;
        height: 60px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 64%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 2%;
        left: -6%;
    }

    .roll-on-gel-image {
        height: 180px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 29px;
        line-height: 1.0;
        margin-left: -2px;
        /*color: #4E0707; /*wine #4E0707 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE */
    }

    /*page two wine*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three wine*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four wine*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five wine*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 900px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six wine*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.7;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven wine*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        padding-left: 21%;
        margin-top: -2%;
    }

    .salve-product-image {
        height: 300px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      margin-top: -10%;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight wine*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 2%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine wine*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten wine*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 2px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 65px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven wine*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        margin-top: -20px;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 600px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -25px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve wine*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 26px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 25px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 321px) and (max-width: 345px) and (min-height: 451px) {
    /*silver #C0C0C0*/
    /* due to iPhone SE, all pages are the same as red (sb) except pg 1, update later*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one silver*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -6%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 27px;
        height: 60px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 64%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 8%;
        left: -6%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 1.0;
        margin-left: -16px;
        /*color: #C0C0C0; /*silver DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two silver*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three silver*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four silver*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 20px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five silver*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six silver*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven silver*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight silver*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine silver*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten silver*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven silver*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 690px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve silver*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (max-height: 370px) {
    /*wheat #f5deb3*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one wheat*/

    .page-one-background {
        height: auto%;
        width: 80%;
        left: 50%;
        margin-left: -40%;
        top: 22%;
        position: absolute;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 30px;
        margin-left: 10%;
        padding-left: 2%;
        padding-right: -40%;
        height: 50px;
        width: 80px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 55%;
        left: 1%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 5%;
        /*color: 	#f5deb3; /*wheat DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two wheat*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 4%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three wheat*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four wheat*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five wheat*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 600px;
        padding-left: 4%;
        margin-right: -20%;
        margin-top: -20%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
        margin-right: 20px;
        margin-left: 20px;
    }

    /*page six wheat*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven wheat*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: -25px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -30px;
      margin-right: 0px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight wheat*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine wheat*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -2%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten wheat*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 18px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven wheat*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 580px;
        height: auto;
        padding-left: 40px;
        padding-top: 0px;
        margin-top: -5px;
        margin-right: -20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
    }

    /*page twelve wheat*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 371px) and (max-height: 415px) {
    /*rose #ad3e51 */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one rose*/

    .page-one-background {
        height: auto%;
        width: 80%;
        left: 50%;
        margin-left: -40%;
        top: 22%;
        position: absolute;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 30px;
        margin-left: 10%;
        padding-left: 2%;
        padding-right: -40%;
        height: 50px;
        width: 80px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 53%;
        left: 1%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 5%;
        /*color: 	#ad3e51; /*rose DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two rose*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three rose*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
        margin-top: 4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four rose*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five rose*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 700px;
        padding-left: 2%;
        margin-right: -20%;
        margin-top: -10%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
        margin-right: 20px;
        margin-left: 20px;
    }

    /*page six rose*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 19px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven rose*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: 10px;
        margin-left: 15%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -20px;
      margin-right: 40px;
      margin-top: 40px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight rose*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine rose*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: 1%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten rose*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 19px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 280px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven rose*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        margin-top: 13%;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 600px;
        height: auto;
        padding-left: 40px;
        padding-top: -5px;
        margin-top: 20px;
        margin-right: -20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
    }

    /*page twelve rose*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 416px) and (max-height: 454px) {
    /* @media only screen (max-width: 375px) and (max-height: 453px) {*/
    /* purple #52307c */
    /* max-height: 450px is not random but not tested - it's just lower than the next criteria to stop other triggers */
    /* not adding min-width because iPhone SE min-height is larger than 453. if device exists, then can add*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one purple 375x367 compact*/

    .page-one-background {
        height: auto%;
        width: 86%;
        left: 50%;
        margin-left: -43%;
        top: 23%;
        position: absolute;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 1%;
        padding-right: -40%;
        margin-left: 9%;
        height: 50px;
        width: 90px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 70%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 55%;
        left: -2%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 58px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: -10%;
        margin-left: 9%;
        /*color: 	#52307c; /*purple DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two purple 375x367 compact*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three purple 375x367 compact*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
        margin-top: 2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 20px;
    }

    /*page four purple 375x367 compact*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 20%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five purple 375x367 compact*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 680px;
        padding-left: 4%;
        margin-right: 2%;
        margin-top: -10%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
    }

    /*page six purple 375x367 compact*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven purple 375x367 compact*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -5px;
    }

    .salve-product-image {
        height: 240px;
        width: auto;
        margin-top: -3%;
        margin-left: 10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -20px;
      margin-right: 30px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight purple 375x367 compact*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 18px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 19px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine purple 375x367 compact*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: 2%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten purple 375x367 compact*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 19px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven purple 375x367 compact*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 550px;
        height: auto;
        padding-left: 20px;
        padding-top: 0px;
        margin-top: 10%;
        margin-left: 15%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
        padding-top: 10px;
    }

    /*page twelve purple 375x367 compact*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 21px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 20px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 455px) and (max-height: 509px) {
    /* RED #FF0000*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one red min375*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -6%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 30px;
        height: 60px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 4%;
        left: -5%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 1.0;
        margin-left: -3px;
        /*color: #FF0000; /*red DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two red min375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three red min375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four red min375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%; /* if too high try 27 */
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five red min375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six red min375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven red min375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight red min375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px; /*copy of logo-size but needs to be bigger, adjust later*/
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine red min375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten red min375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven red min375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 690px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve red min375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 510px) and (max-height: 530px) {
    /*light blue 375 #85d1dd*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one light blue 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -3%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 3%;
        height: 65px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 8%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 36px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #85d1dd; /*light blue 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light blue 375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three light blue 375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four light blue 375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five light blue 375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -12%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six light blue 375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven light blue 375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -70px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight light blue 375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light blue 375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten light blue 375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light blue 375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve light blue 375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 531px) and (max-height: 555px) {
    /*green1 375 #228b22 green1 pg1*/

    /*page one green1 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -4%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 4%;
        height: 65px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 12%;
        left: -8%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #228b22; /*green 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 556px) and (max-height: 579px) {
    /*green2 375 #228b22 green2 pg1*/

    /*page one green2 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -4%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 4%;
        height: 65px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 17%;
        left: -8%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #228b22; /*green 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 531px) and (max-height: 579px) {
    /*green 375 #228b22 green1 and 2 pg2-12*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page two green 375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three green 375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four green 375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five green 375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -4%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six green 375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.9;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven green 375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -60px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight green 375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine green 375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten green 375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven green 375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve green 375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 580px) {
    /* blue #0000ff*/
    /* @media only screen and (min-width: 375px) and (max-width: 388px) and (min-height: 580px) { */
    /* BLUE*/
    /* works for iphone X wrap fullscreen and real wrap - @media only screen and (min-width: 375px) and (max-width: 388px) and (min-height: 580px) and (max-height: 768px) */
    /*notes: works for wrap and wrap in capsule fullscreen - min-height on iPhone X range that works 500-600, 400bad and 610 bad. 580 confirmed on iPHoneX, Nokia 7 Plus and Galaxy J7 */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 45px;
        width: 80px;
    }

    /*page one blue*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 15px;
        height: 75px;
        width: px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 1%;
    }

    .colby-name {
        font-size: 22px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 14%;
        left: -11%;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 30px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 20px;
        position: absolute;
        width: 100%;
        bottom: 26px;
        line-height: 1.0;
        margin-left: -24px;
        /*color: #0000ff; /*blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two blue*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 10%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 41%;
        margin-top: -4%;

    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 40px;
    }

    /*page four blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 168px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five blue*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 28px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2s%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six blue*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 7px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.7;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -4%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven blue*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-bottom: 12px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 340px;
        width: auto;
        margin-top: -20%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      margin-top: -10px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight blue*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 46px;
        width: 92px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 18px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine blue*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 12px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 54%;
        left: 50%;
        margin-left: -27%;
    }

    /*page ten blue*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 23px;
        padding-left: 15px;
        padding-right: -3px;
        margin-right: -50px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 26px;
        white-space: pre !important;
        margin-left: 18px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 70%;
        right: 90%;
        text-align: right;
    }

    .logo-size-ten {
        height: 48px;
        width: auto;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven blue*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 900px;
        height: auto;
        padding-left: 20px;
        padding-top: 30px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve blue*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: 3%;
        margin-bottom: 1%;
        margin-top: 4%;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) {
    /* turquoise #30D5C8*/
    /* iphone 8 plus and iphone 11 wrap in capsule */
    /* was @media only screen and (min-width: 414px) */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one turquoise one 414compact*/

    .page-one-background {
        height: auto;
        width: 86%;
        left: 50%;
        top: -6%;
        margin-left: -43%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 35px;
        height: 65px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 51%;
        left: -3%;
    }

    .roll-on-gel-image {
        height: 220px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 33px;
        line-height: 1.0;
        margin-left: -2px;
        /*color: #30D5C8; /*turquoise DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two turquoise two 414compact*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three turquoise three 414compact*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four turquoise four 414compact*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five turquoise five 414compact*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 42px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 1%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1000px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -15%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six turquoise six 414compact*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%; /*iphone 8 plus a little too high, iPhone 11 good*/
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven turquoise seven 414compact*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -70px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight turquoise eight 414compact*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%; /*iphone 8 plus good, iphone 11 too low*/
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine turquoise nine 414compact*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        /*padding-bottom: 8px;*/
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten turquoise ten 414compact*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven turquoise eleven 414compact*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve turquoise twelve 414compact*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 518px) and (max-height: 556px) {
    /* yellow #ffff00*/
    /*iPhone 12 wrap in capsule brower*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one yellow one*/

    .page-one-background {
        height: auto;
        width: 86%;
        left: 50%;
        top: -6%;
        margin-left: -43%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 35px;
        height: 70px;
        width: 130px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -3.6%;
    }

    .roll-on-gel-image {
        height: 220px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 33px;
        line-height: 1.0;
        margin-left: -12px;
        /*color: #ffff00; /*yellow DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two yellow two*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three yellow*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four yellow*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five yellow*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 42px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 25%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1000px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -14%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six yellow*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven yellow*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -60px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight yellow*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine yellow*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        margin-bottom: 10px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten yellow*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven yellow*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve yellow*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 395px) and (min-height: 557px) and (max-height: 599px) {
    /* steel blue pg 1 only #588BAE*/
    /* iphone 12 web share, iPhone 12 Pro Max web capsule */

    /*page one steel1 blue*/

    .page-one-background {
        height: auto;
        width: 100%;
        left: 50%;
        top: -6%;
        margin-left: -50%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 23px;
        height: 75px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 47%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 245px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 20px;
        line-height: 1.0;
        margin-left: -6px;
        /*color: #588BAE; /*steel blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 396px) and (max-width: 480px) and (min-height: 557px) and (max-height: 599px) {
    /* steel2 blue pg 1 only #588BAE*/

    /*page one steel2 blue*/

    .page-one-background {
        height: auto;
        width: 100%;
        left: 50%;
        top: -6%;
        margin-left: -50%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 60px;
        margin-left: 23px;
        height: 75px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.85;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 53%;
        left: -7%;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 25px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 24px;
        line-height: 1.0;
        margin-left: -10px;
        /*color: #588BAE; /*steel blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 557px) and (max-height: 599px) {
    /* steel blue #588BAE page 2-12 only*/
    /* iPhone 12 share brower capsule*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page two steel blue two*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 8%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three steel blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -1%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four steel blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five steel blue five*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 65px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 18%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1200px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -4%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six steel blue six*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven steel blue seven*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -30px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight steel blue eight*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine steel blue nine*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        margin-bottom: 15px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten steel blue ten*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven steel blue eleven*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 700px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: 16px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve steel blue twelve*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 404px) and (min-height: 600px) and (max-height: 650px) {
    /*light pink #ffb6c1*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one light pink 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        margin-top: 3%;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -9.3%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        top: 160px;
        line-height: 1.0;
        margin-left: -30px;
        /*color: #ffb6c1; /*light pink DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light pink 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 26px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three light pink 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 44%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 45px;
    }

    /*page four light pink 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 23%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 24px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page five light pink 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 1900px;
        width: auto;
        padding-left: 1%;
        margin-right: 0%;
        margin-top: -365%; /*on browser simulator was good but on iphone simulator was too low so raised it.Verify*/
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        margin-top: -205%;
        line-height: 1.1;
    }

    /*page six light pink 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven light pink 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight light pink 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 26px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light pink 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 66%;
        left: 50%;
        margin-left: -33%;
    }

    /*page ten light pink 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        margin-right: -80px;
        margin-top: 15px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 25px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        left: 50%;
        margin-left: 10%;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light pink 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 6px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve light pink 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -1%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 405px) and (max-width: 480px) and (min-height: 600px) and (max-height: 650px) {
    /*orange #ffa500*/
    /*iphone 8 plus separated out from iphone 11 pro max, iphone 11, samsung note 9, nokie 7 plus - the */
    /*taller devices- selected max 650px because higher than 622px iphone 8plus and lower than 695 Nokia*/
    /* Need to check other manufacturers for devices like 8 plus width/height and can adjust range*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one orange 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        margin-top: 3%;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -9.3%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 26px;
        position: absolute;
        width: 100%;
        top: 160px;
        line-height: 1.0;
        margin-left: -20px;
        /*color: #ffa500; /*orange DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two orange 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three orange 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 45px;
    }

    /*page four orange 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 23%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 25px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 29px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five orange 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 1800px;
        width: auto; /*1800px*/
        padding-left: 1%;
        margin-right: 0%;
        margin-top: -350%; /*on browser simulator was good but on iphone simulator was too low so raised it.Verify*/
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 25px;
        text-align: start;
        margin-left: -10px;
        padding-right: 20px;
        margin-top: -190%;
        line-height: 1.1;
    }

    /*page six orange 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%; /*bottom: 4% - iphone 8plus slightly too high, but ok*/
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8; /*0.8*/
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven orange 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight orange 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine orange 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 66%;
        left: 50%;
        margin-left: -33%;
    }

    /*page ten orange 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 24px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven orange 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto; /*1200px*/
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve orange 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px; /*iphone 8 plus font-size: 28*/
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%; /*before also had margin-top: 6%*/
    }

}

@media only screen and (min-width: 376px) and (max-width: 400px) and (min-height: 651px) and (max-height: 755px) {
    /*aqua #00FFFF*/
    /*created for width: 390, max width can be adjusted. Over 400 needs to be in next breakpoint*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 50px;
        width: 100px;
    }

    /*page one aqua 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 70px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 45.2%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 31px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        top: 170px;
        margin-left: -23px;
        line-height: 1.0;
        /*color: #00FFFF; /*aqua DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two aqua 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three aqua 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four aqua 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 25px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 29px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page five aqua 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 500px;
        width: auto;
        margin-top: -40%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -25%;
    }

    /*page six aqua 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 26px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven aqua 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight aqua 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 16px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 27px;
        padding-left: 15px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine aqua 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
    }

    /*page ten aqua 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        margin-right: -80px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-left: 13%;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven aqua 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve aqua 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 401px) and (max-width: 480px) and (min-height: 651px) and (max-height: 785px) {
    /*ash green #668E86*/
    /*was max height 755. temp to 780 cuz too tall but might affet other devices*/
    /*iphone 11 pro max, iphone 11, samsung note 9, nokie 7plus*/
    /* was @media only screen and (min-width: 414px) and (max-width: 767px) and (min-height: 600px) */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one ash green 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 40.2%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 26px;
        position: absolute;
        width: 100%;
        top: 160px;
        margin-left: -20px;
        line-height: 1.0;
        /*color: #668E86; /*ash green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two ash green 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three ash green 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four ash green 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 26px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five ash green 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 500px;
        width: auto;
        margin-top: -40%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -25%;
    }

    /*page six ash green 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 26px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven ash green 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight ash green 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine ash green 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
    }

    /*page ten ash green 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 24px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven ash green 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve ash green 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 786px) {
    /*bright pink #f414cd*/
    /*adjusting based on 753h - assuming nothing triggered before this*/
    /*iphone 11 and 12 wrap in capsules*?

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 60px;
        width: 100px;
    }

    /*page one bright pink*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
        top: 0%;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 50px;
        margin-left: 8%;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 70%;
        top: 1%;
    }

    .colby-name {
        font-size: 25px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 14px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 45%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 315px;
        width: auto;
    }

    .name-text {
        font-size: 34px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 29px;
        position: relative;
        width: 100%;
        left: 89%;
        top: -88px;
        line-height: 0.8;
        /*color: #f414cd; /*bright pink DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two bright pink*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 8%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 28px;
        padding-left: 12px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 12px;
        line-height: 0.8;
    }

    /*page three bright pink*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 34px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 41%;
        top: 2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four bright pink*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 26px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five bright pink*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 26px;
        text-align: center;
        padding-left: 23%;
        padding-bottom: 5%;
        padding-top: 4%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 525px;
        width: auto;
        margin-top: -30%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -20%;
    }

    /*page six bright pink*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 29px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven bright pink*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 39px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 32px;
        text-align: left;
        padding-bottom: 12px;
        padding-left: 27%;
    }

    .salve-product-image {
        height: 570px;
        width: auto;
        margin-top: -30%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 27px;
      text-align: start;
      margin-left: -110px;
      margin-right: 18px;
      line-height: 1.3;
      margin-top: -15%;
      margin-right: 10%;
      width: 50%;
    }

    /*page eight bright pink*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 34%;
    }

    .ampudia-page-name {
        display: inline-block;
        position: relative;
        font-family: 'Impact';
        color: #F15922;
        font-size: 31px;
        padding-left: 26px;
        padding-right: 3px;
        margin-bottom: 20px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 28px;
        margin-left: 50px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine bright pink*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
        margin-top: 4%;
    }

    /*page ten bright pink*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 31px;
        padding-left: 20px;
        padding-right: 8px;
        margin-top: 20px;
        margin-bottom: 12px;
        margin-right: -50px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 32px;
        white-space: pre !important;
        margin-left: 30px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 54px;
        width: auto;
        margin-top: 6%;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven bright pink*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 26px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -790px;
    }

    .tincture-product-image {
        width: auto;
        height: 2800px;
        padding-left: 20px;
        padding-top: 32px;
        margin-top: -1180px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: left;
        margin-left: 8px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 29px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve bright pink*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 33px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 33px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 30px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 32px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 481px) and (max-width: 768px) and (max-height: 699px) {
    /*deep red #B30000*/
    /*hasn't triggered until now so setting it initialy for 512 x 577 and 512 x 661*/

    .new-line {
        margin-top: -60px;
    }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one deep red 481*/

    .page-one-background {
        height: auto;
        width: 90%;
        left: 50%;
        top: -1%;
        margin-left: -45%;
        position: relative;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 45px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 72%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: -1%;
        left: -2%;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 28px;
        position: absolute;
        width: 100%;
        bottom: 15%;
        line-height: 1.1;
        left: 110%;
        /*color: #B30000; /*deep red DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/

    }

    /*page two deep red 481*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: -2%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 20px;
    }

    /*page three deep red 481*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 46%;
        top: -5%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four deep red 481*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five deep red 481*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        margin-top: -8%;
        padding-left: 70px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 31px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 3%;
        padding-top: -0%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1400px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -28%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 27px;
        text-align: start;
        margin-top: -10%;
        margin-left: 20px;
        padding-right: 30px;
        line-height: 1.0;
    }

    /*page six deep red 481*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: -3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 29px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven deep red 481*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 32px;
        text-align: left;
        padding-left: 26%;
    }

    .salve-product-image {
        height: 400px;
        width: auto;
        margin-top: -25%;
        margin-left: 5%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 26px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight deep red 481*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: -5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    /*page nine deep red 481*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 52%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
        margin-top: -4%;
    }

    /*page ten deep red 481*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven deep red 481*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        margin-top: -8%;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 800px;
        height: auto;
        padding-left: 60px;
        margin-top: -8%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 40px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 23px;
        text-align: start;
        margin-left: 40px;
        padding-right: 30px;
        line-height: 1.2;
    }

    /*page twelve deep red 481*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 33px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 33px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 35px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    .logo-twelth-position {
        position: absolute;
        right: -12%;
        bottom: -8%;
    }

}

@media only screen and (min-width: 481px) and (max-width: 768px) and (min-height: 700px) {
    /*brown #99774D*/
    /* setting up for Pixel 4L might need to split after this. 512w x 769h */

    .new-line {
        margin-top: -60px;
    }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one brown 481*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 50px;
        margin-left: 40px;
        height: 80px;
        width: 125px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 69%;
        top: 4%;
    }

    .colby-name {
        font-size: 24px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 15px;
        margin-top: -10px;
        line-height: 1.7;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 12%;
        left: -32px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 290px;
        width: auto;
    }

    .name-text {
        font-size: 35px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 30px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #99774D; /*#99774D brown DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two brown 481*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three brown 481*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four brown 481*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five brown 481*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 70px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six brown 481*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven brown 481*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight brown 481*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine brown 481*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 55%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten brown 481*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven brown 481*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve brown 481*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (max-height: 699px) {
    /*gold #D7B740*//* for wrap in capsul* /
    /* FIX about to upload and noticed this was max-height; 600px so gap, updating for now to 699 but check*/
    /* mmm 768 is ipads and 1024 is ipad pro - might need to change it but when turned sideways is less*/
    /* based on this for now - setting max height at 600px but check simulator after for full-wrap - both stil*/
    /* ipad pro triggered - compact - wrap in capsule - emulator for web - w 600 vs 700 should only trigger now in landscape mode*
    /* but it's still triggering even though height shows as 840px */
    /* but if it does would also need to check on a real device since ios app has smaller viewport unless height is the same - verify */
    /* my laptop 11" macair triggered - compact - wrap in capsule */

    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one gold 769*/

    .page-one-background {
        height: 30%;
        width: 76%;
        left: 12%;
        top: -4%;
        position: relative;
    }

    .logo-one {
        margin-top: 2px;
        margin-bottom: 50px;
        margin-left: 90px;
        height: 60px;
        width: 110px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 71%;
        top: 3%;
    }

    .colby-name {
        font-size: 18px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 57%;
        left: 36px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 230px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        width: 100%;
        position: fixed;
        bottom: 5%;
        line-height: 0.9;
        left: 51%;
        margin-top: 0%;
        padding-top: 0%;
        margin-left: 0px;
        /*color: #D7B740; /*gold DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two gold 769*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: -2%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page three gold 769*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four gold 769*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 23px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 27px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page five gold 769*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -3%;
        padding-left: 23%;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 3%;
        padding-top: 2%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 2200px;
        width: 1200px;
        margin-right: -50%;
        margin-left: 45%;
        margin-top: -480%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 25px;
        text-align: start;
        margin-left: 100px;
        padding-right: 80px;
        line-height: 1.0;
        margin-top: -171%; /*  the smaller the negative the lower it goes */
        /* for margin-top -171% - good for .rollon-product-image-height: 2200, width 1200, margin-left 45%, margin-top -480% */
    }

    /*page six gold 769*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: -2%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 24px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        /*right: 2%;*/
        height: auto;
        width: 230px;
        left: 50%;
        margin-left: -130px;
    }

    /*page seven gold 769*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 31%;
        width: 50%;
        margin-left: 10%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -32%;
        margin-left: 10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 24px;
      text-align: start;
      margin-top: -20%;
      margin-left: -10%;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight gold 769*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: -3.4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 24px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page nine gold 769*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 53%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten gold 769*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 30px;
        line-height: 1.6; /*was 1.5 if too low change back to 1.5*/
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven gold 769*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        padding-top: 2%;
        padding-bottom: -10px;
        padding-left: 30px;
        margin-top: -82%;
        line-height: 1.0;
    }

    .tincture-product-image {
        width: auto; /*1200px*/
        height: 1080px;
        margin-left: 70%;
        margin-right: -40%;
        padding-top: 0px;
        margin-top: -265%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 32%;
        margin-top: 3%;
        margin-bottom: 1%
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        width: 50%;
        font-size: 23px;
        text-align: start;
        margin-left: 32%;
        margin-right: -1%;
        line-height: 1.2;
    }

    /*page twelve gold 769*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: -2%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 29px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.7;
    }

    .logo-twelth-position {
        position: absolute;
        right: -40%;
        bottom: 20%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (min-height: 700px) {
    /*bright blue #BFEFFF*/
    /* mmm 768 is ipads and 1024 is ipad pro - might need to change it but */
    /* based on this for now - setting min height at 701px, 1 higher than previous breakpoint so full-wrap sb here but check simulator-yes so far */
    /* was @media only screen and (min-width: 960px) */

    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one bright blue 769*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #BFEFFF; /*bright blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two bright blue 769*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three bright blue 769*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four bright blue 769*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five bright blue 769*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six bright blue 769*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven bright blue 769*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight bright blue 769*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine bright blue 769*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 60%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten bright blue 769*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven bright blue 769*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve bright blue 769*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    /*lime green #C0FF00/
    /* was @media only screen and (min-width: 960px) */
    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one lime green 1025*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #C0FF00; /*lime green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two lime green 1025*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three lime green 1025*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four lime green 1025*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five lime green 1025*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six lime green 1025*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven lime green 1025*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight lime green 1025*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine lime green 1025*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 55%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten lime green 1025*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven lime green 1025*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve lime green 1025*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }
}

@media only screen and (min-width: 1201px) {
    /*lavender #b57edc*/
    /* was @media only screen and (min-width: 960px) */
    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one lavender 1201*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #b57edc; /*lavender DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two lavender 1201*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Danny Way 198x3 Background.1176d343.png");
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three lavender 1201*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("/static/media/Plan B Roller Image.be1bd8d5.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 46%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four lavender 1201*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Chase Webb 198x3 Background.e9b05fd2.png");
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five lavender 1201*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -15%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six lavender 1201*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("/static/media/ColbyRaha 198x3 BottomSceneryOnly.c1166006.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven lavender 1201*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight lavender 1201*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/Ampudia 2x3 Background.19e34133.png");
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 8%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine lavender 1201*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("/static/media/Roll-On Gel Mockup (3 bottles-Trans)-25percent.9c3090cf.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 50%;
        margin-top: 0%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten lavender 1201*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url("/static/media/Koa Rothamn 19x3 Background.93e963bb.png");
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven lavender 1201*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1000px;
        height: auto;
        padding-left: 50px;
        margin-top: -4%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve lavender 1201*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("/static/media/NickBruce 188x3 Background.3965b380.png");
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

