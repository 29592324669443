.logo-container {
   padding-top: 25px;
   text-align: center;
   padding-bottom: 20%;
}

.logo-container.email-container {
    padding-bottom: 25px;
}

.logo-container.sign-up-container {
    padding-bottom: 10%;
}

.sign-up-input-container {
    padding-bottom: 15px;
}

.sign-up-input {
    padding-bottom: 20px;
}

.logo {
    max-height: 200px;
    width: auto;
}

.success-container {
    margin-top: 60px;
}

.logo.signup {
    max-height: 130px;
    width: auto;
}

.logo.email-confirm {
    max-height: 100%;
    width: auto;
    margin: 10px;
}

.logo-container.success {
    margin-top: 45px;
}

.back {
    max-height: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.logo.change-mail {
    max-height: 40%;
    width: auto;
    margin: 10px;
}

.confirm-text {
    font-size: 35px;
}

.next.success {
    padding-top: 20px;
    margin-bottom: 0;
    cursor: pointer;
}

.login-buttons {
    position: absolute;
  top: calc(var(--vh, 1vh) * 80);
  left: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.login-input {
    appearance: none;
    background-color: rgba(60,117,239, 0.25);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    outline: none !important;
    text-align: center;
    margin: 0 auto;
    border: 3px solid #4273F0;
    border-radius: 5px;
    width: 100%;
    outline-color: transparent;
    outline-style: none;

}

.selected-dial-code {
    color: white;
}

.login-button {
    margin-top: 15px;
    color: blue;
    width: 100px;
    border-color: #0E60EC;
    border-radius: 20px;
}

.terms {
    color: white !important;
    text-decoration: underline;
}

.terms:hover {
    color: grey !important;
    text-decoration: underline;
}

@media (max-width: 1023px) {
  .signup-spacing {
      margin-top: 50px;
  }
}

@media (min-width: 1024px) {
  .signup-spacing {
      margin-top: 0px;
  }
}

.logo-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 320px) and (max-width: 339px) {
    .logo-center {
        width: 93%;
    }
}

@media only screen and (min-width: 340px) and (max-width: 359px) {
    .logo-center {
        width: 86%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 385px) {
    .logo-center {
        width: 80%;
    }
}

@media only screen and (min-width: 386px) and (max-width: 428px) {
    .logo-center {
        width: 74%;
    }
}

@media only screen and (min-width: 429px) and (max-width: 440px) {
    .logo-center {
        width: 65%;
    }
}

@media only screen and (min-width: 441px) and (max-width: 480px) {
    .logo-center {
      width: 63%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .logo-center {
      width: 56%;
    }
}

@media only screen and (min-width: 768px) {
  .logo-center {
      width: 41%;
  }
}

.invalid-input {
    border-color: red;
}

.login-input::placeholder {
    color: white;
    text-align: center;
}

.phone-input {
    padding-left: 0px !important;
}

.flag-container {
    color: black;
}

.sign-up {
    cursor: pointer;
}

.sign-up-disabled {
    opacity: 0.4;
}

.logo-effect {
    position: absolute;
    top: -57px;
    left: -51px;
    max-height: 72%;
    width: auto
}

.top-text {
    font-size: 30px;
    letter-spacing: 15px;
}

.bottom-text {
    margin-top: 15px;
    font-size: 20px;
}

.login {
    cursor: pointer;
    padding-top: 15px;
}

.login.change-email {
    padding-top: 0;
}

.terms-container {
    padding-top: 30px;
}

.email-validated {
    margin-top: 50%;
}

.next {
    margin-bottom: 50px;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
     -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
 -webkit-user-select: none; /* Chrome, Safari, and Opera */
 -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
