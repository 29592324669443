/*For mobile devices*/
/* @media (min-width: 0 px) and (max-width: 359px){
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 30px;
		left: 36%;
	}
} */

@media only screen and (max-width: 374px) {
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 44px;
		width: 140px;
		left: 50%;
		margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (max-height: 453px) {
	/*purple - wrap in capsule - iphone SE & iphone 8 */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 44px;
		width: 140px;
		left: 50%;
		margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 454px) {
	/* navy blue - full wrap - iphone SE & iphone 8 */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 455px) {
	/* red - wrap in capsule - iphone X */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 14%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (max-width: 375px) and (min-height: 580px) {
	/* blue - full wrap - iphone X, Huawei Mate 20, iPhone 11 Pro */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 376px) and (max-height: 480px) {
	/* adding because turquoise falls into this undefined area*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 8%;
			width: 140px;
			left: 50%;
			margin-left: -70px;
	}
}

@media only screen and (min-width: 376px) and (max-width: 410px) and (min-height: 481px) {
	/* adding because turquoise falls into this undefined area - max height less than 514 iPHone 8 Plus can adjust lower if needed*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 13%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 411px) and (max-height: 480px) {
	/*this is added to allow iphone 8 plus viewport to have the buy button lowered.*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 7%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

@media only screen and (min-width: 411px) and (min-height: 481px) and (max-height: 690px) {
	/*this is added because of the iphone 8 plus (included above) to separate vuewport height of iphone 8plus from iphone 11 types.*/
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 10%;
			width: 190px;
			left: 50%;
			margin-left: -95px;
	}
}

@media only screen and (min-width: 411px) and (min-height: 691px) {
	/*created for phones taller than ihpone 11 types */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 12.6%;
			width: 260px;
			left: 50%;
			margin-left: -130px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (max-height: 600px) {
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 5%;
			width: 180px;
			left: 50%;
			margin-left: -90px;
	}
}

/*@media only screen and (min-width: 768px) and (max-width: 1200px) and (min-height: 601px){ */
@media only screen and (min-width: 768px) and (max-width: 1099px) and (min-height: 601px){
	/* find device that might fit in this range to try it. Revised to max-width:1099, not sure what the 601 is for - check css */
	.shopify-buy-frame--product {
			position: absolute !important;
			bottom: 13%;
			width: 240px;
			left: 50%;
			margin-left: -120px;
	}
}

/*@media only screen and (min-width: 1201px) {*/
@media only screen and (min-width: 1100px) and (max-height: 750px) {
/*added max-height based on estimated Attila desktop share wrap in capsule, est at 804px so selecting 750, below larger buy button-need to check all devices */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 50px;
		width: 240px;
		left: 50%;
		margin-left: -120px;
	}
}

/*@media only screen and (min-width: 1201px) {*/
@media only screen and (min-width: 1100px) and (min-height: 751px) {
/*added max-height based on estimated Attila desktop share wrap in capsule */
	.shopify-buy-frame--product {
		position: absolute !important;
		bottom: 12%;
		width: 260px;
		left: 50%;
		margin-left: -130px;
	}
}

.viewport-info-top {
	position: absolute;
	top: 25%;
}

.prevent-zoom {
	touch-action: manipulation;
}

.wrap {
	overflow:hidden;
}

.flip-area {
	position: absolute;
	max-width: 650px;
	text-align: center;
}

.z-index {
	z-index: 1000;
}

.transition {
	transition: transform 0.5s;
}

.notransition {
	transition: none !important;
}
.bottom-area {
	height: 500px;
	top: 500px;
	background-color: black;
	max-width: 400px;
}

/*).video-mute {
		position: absolute;
		top: 60%;
		right: 5%;
		z-index: 5000;
		cursor: pointer;
		border-radius: 2px;
}*/

.volume-icon {
		height: 22px;
		width: auto;
}

.language-selector {
	position: absolute;
	right: 3%;
	height: 60px;
	cursor: pointer;
	border-radius: 15px;
}

.switchDisplay-selector {
	position: absolute;
	top: 12px;
	right: 21%;
	height: 28px;
	cursor: pointer;
	border-radius: 0px;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.6;
}

.slider-buttons {
	position: absolute;
	top: 65%;
	right: 4.5% !important;
	filter: brightness(8);
}

@media only screen and (max-width: 959px) {
	.video-buttons {
		position: absolute;
		top: 58%;
		right: 9% !important;
		filter: brightness(5);
	}
}

@media only screen and (min-width: 960px) {
	.video-buttons {
		position: absolute;
		top: 58.5%;
		right: 5.5% !important;
		filter: brightness(5);
	}
}

.right-slider {
	height: 10px;
	width: auto;
	transform: rotate(90deg);
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.left-slider {
	height: 10px;
	width: auto;
	transform: rotate(-90deg);
}

.wrap-video {
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
	pointer-events: all !important;
	border-radius: 0px;
	background-color: black !important;
	cursor: default !important;

}

.secondary-info-container {
	position: absolute;
	top:75%;
	left: 50%;
	transform:translate(-50%,-20%);
	margin: auto;
}

.info-container {
	position: absolute;
	top:70%;
	left: 50%;
	transform:translate(-50%,-30%);
	margin: auto;
}

.float-frame {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	visibility: hidden;
	z-index: 2500;
}

.frame-visible {

		visibility: visible;

}

.wrapper {
	height: 100%;
	width: 100%;
}

.info-center {
	margin: auto;
	text-align: center;
	width: 250px;
}

.info-bg {
	background: linear-gradient(90deg, rgba(0,0,6,0) 0%, rgba(0,0,0,0.7035189075630253) 29%, rgba(0,0,0,0.6951155462184874) 67%, rgba(0,0,0,0) 100%);
}

.clickable {
		position: relative;
		z-index: 500;
}

.create-wrap-container {
	height: 500px;
	max-width: 400px;
}

.hide-frame-button {
	position: absolute;
	height: 25px;
	width: 25px;
	right: 20px;
	top: 5px;
	text-align: center;
	z-index: 2500;
	background-color: rgba(0, 0, 0, 0.37);
	border-radius: 25px;
	cursor: pointer;
}

.bg-black {
	background-color: black;
}

.wrap-area {
	z-index: 1500;
	pointer-events: none;
}

.half-wrap-container {
	position: relative;
	height: 250px;
}

#page {
	transform-origin: center left;
	position:absolute;
	height: 600px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#videopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#partialvideopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#imagepage {
	transform-origin: center left;
	position:absolute;
	height: auto;
	width: auto;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-color: black !important;
	left: 0;
	right: 0;
	background-size: auto 100%;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
}

#partialimagepage {
	transform-origin: center left;
	position:absolute;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-color: black !important;
	left: 0;
	right: 0;
	background-size: auto 100%;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
}

.invisible {
	visibility: hidden;
}

#fullvideopage {
	transform-origin: center left;
	position:absolute;
	height: 100%;
	width: 100vw;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-size: 100% 100%;
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
	user-select: none;
	z-index: 500;
}

.video-background {
	object-fit: contain !important;
}

#fullvideopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#videopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#partialvideopage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#imagepage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#partialimagepage.flipped {
	transform: perspective(3000px) rotateY(-90deg);
}

#page.flipped{

	transform: perspective(3000px) rotateY(-90deg);

}

.hidden {
	visibility: hidden;
}
