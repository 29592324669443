.search-input {
    padding-right: 0px;
}

.search-input-text {
    color:white !important;
}

.search-text {
    margin-left: 5px;
}

.p-right-0 {
    padding-right: 0;
}

.p-left-0 {
    padding-left: 0;
}

.searchIcon {
    height: 20px;
    cursor: pointer;
}

.mute-icon {
    height: 20px;
    filter: brightness(3);
    margin-top: auto;
    text-align: center;
}

.edit-text {
    padding-left: 10px;
}