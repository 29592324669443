/*global styles*/

@font-face {
    font-family: 'BigNoodleTitlingOpaque';
    src: url('./fonts/big_noodle_titling_oblique.ttf');
}

@font-face {
    font-family: 'RockwellExtraBold';
    src: url('./fonts/rockb.ttf');
}

@font-face {
    font-family: 'RockwellBold';
    src: url('./fonts/ROCK.TTF');
}

@font-face {
    font-family: 'Impact';
    src: url('./fonts/impact.ttf');
}

@font-face {
    font-family: 'Serif';
    src: url('./fonts/PTF55F.ttf');
}

html, body {
    height: 100vh;
}

.new-line {
    margin-top: -60px;
}

.page-container {
    height: 100%;
    max-width: 100%;
}

.page-container1 {
    position: relative;
    text-align: center;
}

.product-wrapper {
    display: flex;
    align-items: center;
}

.product-wrapperColumn {
    display: flex;
    flex-direction: column;
}

body {
    /*touch-action: manipulation;*/
}

/*@media only screen and (device-width: 414px) and(device-height: 896px) and(-webkit-device-pixel-ratio: 3) and(orientation: portrait) {
    .stage {
        margin-top:48px
    }
}*/

/*@media only screen and (device-width: 375px) and(device-height: 812px) and(-webkit-device-pixel-ratio: 3) and(orientation: portrait) {
    .stage {
        margin-top:48px
    }
}*/

@media only screen and (max-width: 320px) and (max-height: 350px) {
    /* sea green #00FFAA*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one sea green 320*/

    .page-one-background {
        height: auto%;
        width: 82%;
        left: 50%;
        margin-left: -41%;
        top: 23%;
        position: absolute;
    }

    .logo-one {
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left: 20px;
        padding-left: 4%;
        padding-right: -40%;
        height: 50px;
        width: 90px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 52%;
        left: -2%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px; /*if want text higher, increase the positive number */
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 2px;
        /*color: 	#00FFAA; /*sea green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two sea green 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 4%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three sea green 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four sea green 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five sea green 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 550px;
        padding-left: 4%;
        margin-right: 2%;
        margin-top: -20%;
        padding-top: -10px;
        margin-left: 6%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: -3px;
        line-height: 1.0;
        padding-right: 5px;
        padding-left: 5px;
    }

    /*page six sea green 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 1%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;
    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven sea green 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 28%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: -25px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -30px;
      margin-right: 0px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight sea green 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4.4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine sea green 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -3%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten sea green 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 17px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven sea green 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 500px;
        height: auto;
        padding-left: 20px;
        padding-top: -15px;
        margin-top: -35px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 0.9;
    }

    /*page twelve sea green 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-left: 260%;
        margin-bottom: 2%;
    }
}

@media only screen and (max-width: 320px) and (min-height: 351px) and (max-height: 470px) {
    /* NAVY BLUE #000080 */
    /*iphone 5s - full wrap*/
    /* not adding min-width because iPhone SE min-height is larger than 453. if device exists, then can add*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one navy blue*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 1%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        margin-top: 3px;
        margin-left: -9px;
        /*color: #000080; /*navy blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two navy blue*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three navy blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four navy blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five navy blue*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -12%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six navy blue*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven navy blue*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -20px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight navy blue*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine navy blue*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -7px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten navy blue*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven navy blue*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve navy blue*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (max-width: 320px) and (min-height: 471px) and (max-height: 515px) {
    /* light yellow 320 #fffec8*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one light yellow 320*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 7%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        left: 89%;
        top: 70%;
        line-height: 0.8;
        /*color: #fffec8; /*light yellow DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light yellow 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three light yellow 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four light yellow 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five light yellow 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -5%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six light yellow 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;
    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven light yellow 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -5%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight light yellow 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light yellow 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -7px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten light yellow 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light yellow 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: 12%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve light yellow 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (max-width: 320px) and (min-height: 516px) {
    /*water blue 320 #DBF3FA*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 37px;
        width: 74px;
    }

    /*page one water blue 320*/

    .page-one-background {
        height: auto%;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left:15px;
        height: 65px;
        width: 100px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 17px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -3px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 13%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 190px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 25px;
        line-height: 0.9;
        left: 89%;
        top: 70%;
        line-height: 0.8;
        /*color: #DBF3FA; /*water blue 320 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two water blue 320*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 22px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three water blue 320*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 28px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four water blue 320*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 26%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five water blue 320*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -5%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six water blue 320*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 5px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven water blue 320*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 33px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -10px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 250px;
        width: auto;
        margin-top: -1%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight water blue 320*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine water blue 320*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -3px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten water blue 320*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 10px;
        padding-right: 8px;
        margin-right: -20px;
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-right: 10px;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven water blue 320*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 19px;
        padding-top: 10px;
        margin-top: 30%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 28px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 19px;
        text-align: start;
        margin-left: 28px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page twelve water blue 320*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -3%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 321px) and (max-width: 345px) and (max-height: 450px) {
    /*wine #4E0707*/
    /* due to iPhone SE, all pages are the same as red (sb) except pg 1, update later*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one wine*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -10%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 27px;
        height: 60px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 64%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 2%;
        left: -6%;
    }

    .roll-on-gel-image {
        height: 180px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 29px;
        line-height: 1.0;
        margin-left: -2px;
        /*color: #4E0707; /*wine #4E0707 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE */
    }

    /*page two wine*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three wine*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four wine*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 21px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 19px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 22px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five wine*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 900px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six wine*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.7;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven wine*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        padding-left: 21%;
        margin-top: -2%;
    }

    .salve-product-image {
        height: 300px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 20px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      margin-top: -10%;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight wine*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 2%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine wine*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten wine*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 2px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 65px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven wine*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        margin-top: -20px;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 600px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -25px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 25px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve wine*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 26px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 25px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 321px) and (max-width: 345px) and (min-height: 451px) {
    /*silver #C0C0C0*/
    /* due to iPhone SE, all pages are the same as red (sb) except pg 1, update later*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one silver*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -6%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 27px;
        height: 60px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 64%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 8%;
        left: -6%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 1.0;
        margin-left: -16px;
        /*color: #C0C0C0; /*silver DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two silver*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three silver*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four silver*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 20px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five silver*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six silver*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven silver*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight silver*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine silver*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten silver*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven silver*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 690px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve silver*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (max-height: 370px) {
    /*wheat #f5deb3*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one wheat*/

    .page-one-background {
        height: auto%;
        width: 80%;
        left: 50%;
        margin-left: -40%;
        top: 22%;
        position: absolute;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 30px;
        margin-left: 10%;
        padding-left: 2%;
        padding-right: -40%;
        height: 50px;
        width: 80px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 55%;
        left: 1%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 5%;
        /*color: 	#f5deb3; /*wheat DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two wheat*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 4%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three wheat*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four wheat*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five wheat*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 600px;
        padding-left: 4%;
        margin-right: -20%;
        margin-top: -20%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
        margin-right: 20px;
        margin-left: 20px;
    }

    /*page six wheat*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven wheat*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: -25px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -30px;
      margin-right: 0px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight wheat*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine wheat*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -2%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten wheat*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 18px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven wheat*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 580px;
        height: auto;
        padding-left: 40px;
        padding-top: 0px;
        margin-top: -5px;
        margin-right: -20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
    }

    /*page twelve wheat*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 371px) and (max-height: 415px) {
    /*rose #ad3e51 */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one rose*/

    .page-one-background {
        height: auto%;
        width: 80%;
        left: 50%;
        margin-left: -40%;
        top: 22%;
        position: absolute;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 30px;
        margin-left: 10%;
        padding-left: 2%;
        padding-right: -40%;
        height: 50px;
        width: 80px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 53%;
        left: 1%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 36px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: 20%;
        margin-left: 5%;
        /*color: 	#ad3e51; /*rose DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two rose*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 19px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three rose*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
        margin-top: 4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 5px;
    }

    /*page four rose*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five rose*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 700px;
        padding-left: 2%;
        margin-right: -20%;
        margin-top: -10%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
        margin-right: 20px;
        margin-left: 20px;
    }

    /*page six rose*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 19px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven rose*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -8px;
    }

    .salve-product-image {
        height: 180px;
        width: auto;
        margin-top: 10px;
        margin-left: 15%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -20px;
      margin-right: 40px;
      margin-top: 40px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight rose*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 17px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine rose*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: 1%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten rose*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 19px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 280px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven rose*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        margin-top: 13%;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 600px;
        height: auto;
        padding-left: 40px;
        padding-top: -5px;
        margin-top: 20px;
        margin-right: -20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 19px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 17px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
    }

    /*page twelve rose*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 20px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 19px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 416px) and (max-height: 454px) {
    /* @media only screen (max-width: 375px) and (max-height: 453px) {*/
    /* purple #52307c */
    /* max-height: 450px is not random but not tested - it's just lower than the next criteria to stop other triggers */
    /* not adding min-width because iPhone SE min-height is larger than 453. if device exists, then can add*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 32px;
        width: 60px;
    }

    /*page one purple 375x367 compact*/

    .page-one-background {
        height: auto%;
        width: 86%;
        left: 50%;
        margin-left: -43%;
        top: 23%;
        position: absolute;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 1%;
        padding-right: -40%;
        margin-left: 9%;
        height: 50px;
        width: 90px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 70%;
        top: 4%;
    }

    .colby-name {
        font-size: 13px;
        color: rgb(255,102,51);
        /*line-height: 1.8;*/
    }

    .sub-title {
        font-size: 9px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 55%;
        left: -2%;
    }

    .roll-on-gel-image {
        height: 160px;
        width: auto;
    }

    .name-text {
        font-size: 20px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 18px;
        width: 100%;
        position: fixed;
        bottom: 58px;
        line-height: 0.9;
        margin-top: 15%;
        padding-top: -10%;
        margin-left: 9%;
        /*color: 	#52307c; /*purple DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two purple 375x367 compact*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 19px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 20px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three purple 375x367 compact*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 24px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 43%;
        margin-top: 2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 22px;
        text-align: center;
        padding-bottom: 20px;
    }

    /*page four purple 375x367 compact*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 20px;
        margin-left: 10px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 20%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 18px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 20px;
        margin-left: 10px;
        white-space: pre !important;
        line-height: 0.8;
    }

    /*page five purple 375x367 compact*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 21px;
        text-align: left;
        width: 30%;
        margin-top: -5%;
        padding-left: 48px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        width: 70%;
        text-align: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 680px;
        padding-left: 4%;
        margin-right: 2%;
        margin-top: -10%;
        padding-top: -10px;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-top: 2px;
        line-height: 1.0;
    }

    /*page six purple 375x367 compact*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 20px;
        margin-left: 10px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 5px;
        margin-bottom: 8px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 17px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 18px;
        padding-left: 10px;
        white-space: pre !important;
        margin-top: -6px;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven purple 375x367 compact*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 25px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        padding-left: 30%;
        margin-top: -5px;
    }

    .salve-product-image {
        height: 240px;
        width: auto;
        margin-top: -3%;
        margin-left: 10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 17px;
      text-align: start;
      margin-left: -20px;
      margin-right: 30px;
      margin-top: 5px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight purple 375x367 compact*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        font-size: 18px;
        color: white;
        padding-left: 4px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 19px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine purple 375x367 compact*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 23px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: 2%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten purple 375x367 compact*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 20px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 12px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 19px;
        white-space: pre !important;
        margin-top: -10px;
        margin-left: 15px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven purple 375x367 compact*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 16px;
        text-align: center;
        padding-top: -30px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
        line-height: 0.9;
    }

    .tincture-product-image {
        width: 550px;
        height: auto;
        padding-left: 20px;
        padding-top: 0px;
        margin-top: 10%;
        margin-left: 15%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 20px;
        text-align: left;
        margin-left: 27px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 18px;
        text-align: start;
        margin-left: 28px;
        padding-right: 30px;
        line-height: 0.9;
        padding-top: 10px;
    }

    /*page twelve purple 375x367 compact*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 21px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 20px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 21px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
        margin-top: -15px;
    }

    .logo-twelth-position {
        margin-right: -15%;
        margin-bottom: 2%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 455px) and (max-height: 509px) {
    /* RED #FF0000*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one red min375*/

    .page-one-background {
        height: auto%;
        width: 90%;
        position: relative;
        left: 50%;
        margin-left: -45%;
        margin-top: -6%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 30px;
        height: 60px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 0%;
    }

    .colby-name {
        font-size: 19px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 11px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 4%;
        left: -5%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 21px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 1.0;
        margin-left: -3px;
        /*color: #FF0000; /*red DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two red min375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 7px;
        line-height: 0.8;
    }

    /*page three red min375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four red min375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%; /* if too high try 27 */
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five red min375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -18%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six red min375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 22px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 20px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven red min375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -80px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight red min375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px; /*copy of logo-size but needs to be bigger, adjust later*/
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine red min375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten red min375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 21px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-right: -20px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-right: 15px
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven red min375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 690px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve red min375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 27px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 26px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 510px) and (max-height: 530px) {
    /*light blue 375 #85d1dd*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one light blue 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -3%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 3%;
        height: 65px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 8%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 36px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #85d1dd; /*light blue 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light blue 375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three light blue 375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four light blue 375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five light blue 375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1100px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -12%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six light blue 375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven light blue 375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -70px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight light blue 375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light blue 375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten light blue 375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light blue 375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve light blue 375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 531px) and (max-height: 555px) {
    /*green1 375 #228b22 green1 pg1*/

    /*page one green1 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -4%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 4%;
        height: 65px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 12%;
        left: -8%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #228b22; /*green 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 556px) and (max-height: 579px) {
    /*green2 375 #228b22 green2 pg1*/

    /*page one green2 375*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
        margin-top: -4%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 4%;
        height: 65px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 67%;
        top: 0%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 17%;
        left: -8%;
    }

    .roll-on-gel-image {
        height: 200px;
        width: auto;
    }

    .name-text {
        font-size: 27px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 35px;
        line-height: 0.9;
        margin-left: 0px;
        /*color: #228b22; /*green 375 DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 531px) and (max-height: 579px) {
    /*green 375 #228b22 green1 and 2 pg2-12*/
    /* @media only screen and (max-width: 375px) works for iphone X compact */
    /* not sure why set at 455px - needs to be tested */
    /* was @media only screen and (max-width: 375px) and (min-height: 455px) { */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page two green 375*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 22px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three green 375*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: 0%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 15px;
    }

    /*page four green 375*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 22%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.9;
    }

    /*page five green 375*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 30px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1300px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: -4%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six green 375*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.9;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven green 375*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -60px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight green 375*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 40px;
        width: 80px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine green 375*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -8px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten green 375*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: -30px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven green 375*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 18px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve green 375*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -4%;
        margin-bottom: 2%;
        margin-top: -2%;
    }

}

@media only screen and (min-width: 346px) and (max-width: 375px) and (min-height: 580px) {
    /* blue #0000ff*/
    /* @media only screen and (min-width: 375px) and (max-width: 388px) and (min-height: 580px) { */
    /* BLUE*/
    /* works for iphone X wrap fullscreen and real wrap - @media only screen and (min-width: 375px) and (max-width: 388px) and (min-height: 580px) and (max-height: 768px) */
    /*notes: works for wrap and wrap in capsule fullscreen - min-height on iPhone X range that works 500-600, 400bad and 610 bad. 580 confirmed on iPHoneX, Nokia 7 Plus and Galaxy J7 */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 45px;
        width: 80px;
    }

    /*page one blue*/

    .page-one-background {
        height: 100%;
        width: auto;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 15px;
        height: 75px;
        width: px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 1%;
    }

    .colby-name {
        font-size: 22px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 14%;
        left: -11%;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 30px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 20px;
        position: absolute;
        width: 100%;
        bottom: 26px;
        line-height: 1.0;
        margin-left: -24px;
        /*color: #0000ff; /*blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two blue*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 10%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 41%;
        margin-top: -4%;

    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 40px;
    }

    /*page four blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 168px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 21px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five blue*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 28px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 2s%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six blue*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 7px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.7;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -4%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven blue*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-bottom: 12px;
        padding-left: 22%;
    }

    .salve-product-image {
        height: 340px;
        width: auto;
        margin-top: -20%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      margin-top: -10px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight blue*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 46px;
        width: 92px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 18px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine blue*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 12px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 54%;
        left: 50%;
        margin-left: -27%;
    }

    /*page ten blue*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 23px;
        padding-left: 15px;
        padding-right: -3px;
        margin-right: -50px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 26px;
        white-space: pre !important;
        margin-left: 18px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 70%;
        right: 90%;
        text-align: right;
    }

    .logo-size-ten {
        height: 48px;
        width: auto;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven blue*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 20px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 900px;
        height: auto;
        padding-left: 20px;
        padding-top: 30px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        margin-left: 30px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve blue*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: 3%;
        margin-bottom: 1%;
        margin-top: 4%;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) {
    /* turquoise #30D5C8*/
    /* iphone 8 plus and iphone 11 wrap in capsule */
    /* was @media only screen and (min-width: 414px) */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one turquoise one 414compact*/

    .page-one-background {
        height: auto;
        width: 86%;
        left: 50%;
        top: -6%;
        margin-left: -43%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 35px;
        height: 65px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 51%;
        left: -3%;
    }

    .roll-on-gel-image {
        height: 220px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 33px;
        line-height: 1.0;
        margin-left: -2px;
        /*color: #30D5C8; /*turquoise DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two turquoise two 414compact*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 5%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three turquoise three 414compact*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four turquoise four 414compact*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five turquoise five 414compact*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 42px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 1%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1000px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -15%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six turquoise six 414compact*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 3%; /*iphone 8 plus a little too high, iPhone 11 good*/
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven turquoise seven 414compact*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -70px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight turquoise eight 414compact*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%; /*iphone 8 plus good, iphone 11 too low*/
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine turquoise nine 414compact*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        /*padding-bottom: 8px;*/
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten turquoise ten 414compact*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven turquoise eleven 414compact*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve turquoise twelve 414compact*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 518px) and (max-height: 556px) {
    /* yellow #ffff00*/
    /*iPhone 12 wrap in capsule brower*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one yellow one*/

    .page-one-background {
        height: auto;
        width: 86%;
        left: 50%;
        top: -6%;
        margin-left: -43%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 35px;
        height: 70px;
        width: 130px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 66%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -3.6%;
    }

    .roll-on-gel-image {
        height: 220px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 33px;
        line-height: 1.0;
        margin-left: -12px;
        /*color: #ffff00; /*yellow DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two yellow two*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three yellow*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four yellow*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five yellow*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 42px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 25%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1000px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -14%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six yellow*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven yellow*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -60px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight yellow*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 9%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine yellow*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        margin-bottom: 10px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten yellow*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven yellow*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 670px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: -10px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve yellow*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 395px) and (min-height: 557px) and (max-height: 599px) {
    /* steel blue pg 1 only #588BAE*/
    /* iphone 12 web share, iPhone 12 Pro Max web capsule */

    /*page one steel1 blue*/

    .page-one-background {
        height: auto;
        width: 100%;
        left: 50%;
        top: -6%;
        margin-left: -50%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 23px;
        height: 75px;
        width: 140px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 47%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 245px;
        width: auto;
    }

    .name-text {
        font-size: 24px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 20px;
        line-height: 1.0;
        margin-left: -6px;
        /*color: #588BAE; /*steel blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 396px) and (max-width: 480px) and (min-height: 557px) and (max-height: 599px) {
    /* steel2 blue pg 1 only #588BAE*/

    /*page one steel2 blue*/

    .page-one-background {
        height: auto;
        width: 100%;
        left: 50%;
        top: -6%;
        margin-left: -50%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 60px;
        margin-left: 23px;
        height: 75px;
        width: 150px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 2%;
    }

    .colby-name {
        font-size: 20px;
        color: rgb(255,102,51);
        line-height: 1.85;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 53%;
        left: -7%;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 25px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 23px;
        position: absolute;
        width: 100%;
        bottom: 24px;
        line-height: 1.0;
        margin-left: -10px;
        /*color: #588BAE; /*steel blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }
}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 557px) and (max-height: 599px) {
    /* steel blue #588BAE page 2-12 only*/
    /* iPhone 12 share brower capsule*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page two steel blue two*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 8%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 23px;
        padding-left: 13px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 21.5px;
        white-space: pre !important;
        margin-left: 13px;
        line-height: 0.8;
    }

    /*page three steel blue*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 30px;
        padding-top: 40px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        margin-top: -1%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 29px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four steel blue*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 5%;
        right: 8%;
        height: 150px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 21%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 3px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 22px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five steel blue five*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-top: -5%;
        padding-left: 65px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 21px;
        text-align: center;
        padding-left: 18%;
        padding-bottom: 2%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1200px;
        padding-left: 2%;
        margin-right: 0%;
        margin-top: -4%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 10px;
        padding-right: 25px;
        line-height: 1.1;
    }

    /*page six steel blue six*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 23px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 21px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 130px;
        width: auto;
    }

    /*page seven steel blue seven*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 36px;
        padding-top: 40px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 25%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: -30px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 21px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.0;
      width: 50%;
    }

    /*page eight steel blue eight*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 45px;
        width: 90px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -4.8%;
        height: 140px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 22px;
        padding-left: 15px;
        padding-right: 3px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 19px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 21.5px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine steel blue nine*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 33px;
        text-align: center;
        margin-bottom: 15px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten steel blue ten*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 22px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.26);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 23px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 35px;
        width: 70px;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250x;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 140px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -6%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 4.6%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 14.7%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven steel blue eleven*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 19px;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -45px;
    }

    .tincture-product-image {
        width: 700px;
        height: auto;
        padding-left: 20px;
        padding-top: 10px;
        margin-top: 16px;
        margin-right: -10px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 27px;
        text-align: left;
        margin-left: 29px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 20px;
        text-align: start;
        margin-left: 30px;
        padding-right: 20px;
        line-height: 1.1;
    }

    /*page twelve steel blue twelve*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 27px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 20px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -40px;
        margin-top: 30px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 404px) and (min-height: 600px) and (max-height: 650px) {
    /*light pink #ffb6c1*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one light pink 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        margin-top: 3%;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -9.3%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        top: 160px;
        line-height: 1.0;
        margin-left: -30px;
        /*color: #ffb6c1; /*light pink DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two light pink 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 0px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 26px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three light pink 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 44%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 45px;
    }

    /*page four light pink 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 23%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 24px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page five light pink 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 1900px;
        width: auto;
        padding-left: 1%;
        margin-right: 0%;
        margin-top: -365%; /*on browser simulator was good but on iphone simulator was too low so raised it.Verify*/
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        margin-top: -205%;
        line-height: 1.1;
    }

    /*page six light pink 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven light pink 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight light pink 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 26px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine light pink 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 66%;
        left: 50%;
        margin-left: -33%;
    }

    /*page ten light pink 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        margin-right: -80px;
        margin-top: 15px;
        margin-bottom: 3px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 25px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        left: 50%;
        margin-left: 10%;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven light pink 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 6px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve light pink 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -1%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 405px) and (max-width: 480px) and (min-height: 600px) and (max-height: 650px) {
    /*orange #ffa500*/
    /*iphone 8 plus separated out from iphone 11 pro max, iphone 11, samsung note 9, nokie 7 plus - the */
    /*taller devices- selected max 650px because higher than 622px iphone 8plus and lower than 695 Nokia*/
    /* Need to check other manufacturers for devices like 8 plus width/height and can adjust range*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one orange 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        margin-top: 3%;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 48%;
        left: -9.3%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 26px;
        position: absolute;
        width: 100%;
        top: 160px;
        line-height: 1.0;
        margin-left: -20px;
        /*color: #ffa500; /*orange DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two orange 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 6%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three orange 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 45%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 45px;
    }

    /*page four orange 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 23%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 25px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 29px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five orange 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 1800px;
        width: auto; /*1800px*/
        padding-left: 1%;
        margin-right: 0%;
        margin-top: -350%; /*on browser simulator was good but on iphone simulator was too low so raised it.Verify*/
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 25px;
        text-align: start;
        margin-left: -10px;
        padding-right: 20px;
        margin-top: -190%;
        line-height: 1.1;
    }

    /*page six orange 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%; /*bottom: 4% - iphone 8plus slightly too high, but ok*/
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 25px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 23px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8; /*0.8*/
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven orange 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight orange 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 6%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine orange 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 66%;
        left: 50%;
        margin-left: -33%;
    }

    /*page ten orange 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 24px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven orange 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto; /*1200px*/
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve orange 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px; /*iphone 8 plus font-size: 28*/
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%; /*before also had margin-top: 6%*/
    }

}

@media only screen and (min-width: 376px) and (max-width: 400px) and (min-height: 651px) and (max-height: 755px) {
    /*aqua #00FFFF*/
    /*created for width: 390, max width can be adjusted. Over 400 needs to be in next breakpoint*/

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 50px;
        width: 100px;
    }

    /*page one aqua 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 70px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 45.2%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 31px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        top: 170px;
        margin-left: -23px;
        line-height: 1.0;
        /*color: #00FFFF; /*aqua DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two aqua 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three aqua 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four aqua 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 25px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 29px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page five aqua 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 500px;
        width: auto;
        margin-top: -40%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -25%;
    }

    /*page six aqua 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 26px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven aqua 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight aqua 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 16px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 27px;
        padding-left: 15px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine aqua 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
    }

    /*page ten aqua 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        margin-right: -80px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
        margin-left: 13%;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven aqua 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve aqua 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 401px) and (max-width: 480px) and (min-height: 651px) and (max-height: 785px) {
    /*ash green #668E86*/
    /*was max height 755. temp to 780 cuz too tall but might affet other devices*/
    /*iphone 11 pro max, iphone 11, samsung note 9, nokie 7plus*/
    /* was @media only screen and (min-width: 414px) and (max-width: 767px) and (min-height: 600px) */

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 40px;
        width: 80px;
    }

    /*page one ash green 414*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 20px;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 1%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 40.2%;
        left: -10%;
    }

    .roll-on-gel-image {
        height: 250px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 26px;
        position: absolute;
        width: 100%;
        top: 160px;
        margin-left: -20px;
        line-height: 1.0;
        /*color: #668E86; /*ash green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two ash green 414*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 7%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 25px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 25px;
        white-space: pre !important;
        margin-left: 10px;
        line-height: 0.8;
    }

    /*page three ash green 414*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 47%;
        top: -2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four ash green 414*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 26px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five ash green 414*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 5%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 500px;
        width: auto;
        margin-top: -40%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 22px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -25%;
    }

    /*page six ash green 414*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 27px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 26px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven ash green 414*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: left;
        padding-left: 24%;
    }

    .salve-product-image {
        height: 320px;
        width: auto;
        margin-top: 10px;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 23px;
      text-align: start;
      margin-left: -90px;
      margin-right: 18px;
      line-height: 1.2;
      width: 50%;
    }

    /*page eight ash green 414*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 24px;
        padding-left: 23px;
        padding-right: 3px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 22px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine ash green 414*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
    }

    /*page ten ash green 414*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 24px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 26px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven ash green 414*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 22px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -730px;
    }

    .tincture-product-image {
        width: auto;
        height: 2600px;
        padding-left: 20px;
        padding-top: 30px;
        margin-top: -1118px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 31px;
        text-align: left;
        margin-left: 9px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 24px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve ash green 414*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 32px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 30px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 28px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 27px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 376px) and (max-width: 480px) and (min-height: 786px) {
    /*bright pink #f414cd*/
    /*adjusting based on 753h - assuming nothing triggered before this*/
    /*iphone 11 and 12 wrap in capsules*?

    .new-line {
        margin-top: -10px;
    }

    .logo-size {
        height: 60px;
        width: 100px;
    }

    /*page one bright pink*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
        left: 50%;
        margin-left: -50%;
        top: 0%;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 50px;
        margin-left: 8%;
        height: 75px;
        width: 135px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 70%;
        top: 1%;
    }

    .colby-name {
        font-size: 25px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 14px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 45%;
        left: -9%;
    }

    .roll-on-gel-image {
        height: 315px;
        width: auto;
    }

    .name-text {
        font-size: 34px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 29px;
        position: relative;
        width: 100%;
        left: 89%;
        top: -88px;
        line-height: 0.8;
        /*color: #f414cd; /*bright pink DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two bright pink*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 8%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 28px;
        padding-left: 12px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 12px;
        line-height: 0.8;
    }

    /*page three bright pink*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 34px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 41%;
        top: 2%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: center;
        padding-bottom: 50px;
    }

    /*page four bright pink*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 20px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 13%;
        right: 8%;
        height: 174px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 28%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 5px;
        font-size: 26px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 10px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    /*page five bright pink*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        margin-top: -5%;
        padding-left: 50px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 26px;
        text-align: center;
        padding-left: 23%;
        padding-bottom: 5%;
        padding-top: 4%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 525px;
        width: auto;
        margin-top: -30%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: -10px;
        padding-right: 25px;
        line-height: 1.2;
        width: 50%;
        margin-top: -20%;
    }

    /*page six bright pink*/

    .top-background {
        height: 45%;
    }

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 90%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 7%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.26);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 29px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 3px;
        line-height: 0.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 45%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -10%;
        right: 3%;
        height: 160px;
        width: auto;
    }

    /*page seven bright pink*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 39px;
        padding-top: 70px;
        text-align: center;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 32px;
        text-align: left;
        padding-bottom: 12px;
        padding-left: 27%;
    }

    .salve-product-image {
        height: 570px;
        width: auto;
        margin-top: -30%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 27px;
      text-align: start;
      margin-left: -110px;
      margin-right: 18px;
      line-height: 1.3;
      margin-top: -15%;
      margin-right: 10%;
      width: 50%;
    }

    /*page eight bright pink*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 55px;
        width: 110px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 23%;
        right: -9%;
        height: 180px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 34%;
    }

    .ampudia-page-name {
        display: inline-block;
        position: relative;
        font-family: 'Impact';
        color: #F15922;
        font-size: 31px;
        padding-left: 26px;
        padding-right: 3px;
        margin-bottom: 20px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 4px;
        font-size: 28px;
        margin-left: 50px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 0px;
        line-height: 0.8;
    }

    /*page nine bright pink*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 8px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        width: 70%;
        left: 50%;
        margin-left: -35%;
        margin-top: 4%;
    }

    /*page ten bright pink*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 31px;
        padding-left: 20px;
        padding-right: 8px;
        margin-top: 20px;
        margin-bottom: 12px;
        margin-right: -50px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 32px;
        white-space: pre !important;
        margin-left: 30px;
        line-height: 0.8;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 54px;
        width: auto;
        margin-top: 6%;
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 200px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -11%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 98%;
        right: 2.5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 16%;
    }

    .tincture-position {
        position:absolute;
        bottom: 10px;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven bright pink*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 26px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 15px;
        padding-left: -10px;
        margin-left: -55px;
        margin-top: -790px;
    }

    .tincture-product-image {
        width: auto;
        height: 2800px;
        padding-left: 20px;
        padding-top: 32px;
        margin-top: -1180px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 36px;
        text-align: left;
        margin-left: 8px;
        margin-bottom: 10px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 29px;
        text-align: start;
        margin-left: 10px;
        padding-right: 20px;
        line-height: 1.2;
    }

    /*page twelve bright pink*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 12%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 33px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 10px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 33px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 30px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 32px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 0.8;
    }

    .logo-twelth-position {
        margin-right: -2%;
        margin-bottom: -5%;
    }

}

@media only screen and (min-width: 481px) and (max-width: 768px) and (max-height: 699px) {
    /*deep red #B30000*/
    /*hasn't triggered until now so setting it initialy for 512 x 577 and 512 x 661*/

    .new-line {
        margin-top: -60px;
    }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one deep red 481*/

    .page-one-background {
        height: auto;
        width: 90%;
        left: 50%;
        top: -1%;
        margin-left: -45%;
        position: relative;
    }

    .logo-one {
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 45px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 72%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
        line-height: 1.8;
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: -1%;
        left: -2%;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 32px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 28px;
        position: absolute;
        width: 100%;
        bottom: 15%;
        line-height: 1.1;
        left: 110%;
        /*color: #B30000; /*deep red DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/

    }

    /*page two deep red 481*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: -2%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 20px;
    }

    /*page three deep red 481*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 46%;
        top: -5%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four deep red 481*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five deep red 481*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        margin-top: -8%;
        padding-left: 70px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 31px;
        text-align: center;
        padding-left: 19%;
        padding-bottom: 3%;
        padding-top: -0%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1400px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -28%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 27px;
        text-align: start;
        margin-top: -10%;
        margin-left: 20px;
        padding-right: 30px;
        line-height: 1.0;
    }

    /*page six deep red 481*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: -3%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 29px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 28px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven deep red 481*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 32px;
        text-align: left;
        padding-left: 26%;
    }

    .salve-product-image {
        height: 400px;
        width: auto;
        margin-top: -25%;
        margin-left: 5%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 26px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight deep red 481*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: -5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    /*page nine deep red 481*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 52%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
        margin-top: -4%;
    }

    /*page ten deep red 481*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven deep red 481*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        margin-top: -8%;
        padding-bottom: 10px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 800px;
        height: auto;
        padding-left: 60px;
        margin-top: -8%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-left: 40px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 23px;
        text-align: start;
        margin-left: 40px;
        padding-right: 30px;
        line-height: 1.2;
    }

    /*page twelve deep red 481*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 10%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 33px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 33px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 35px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 30px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.6;
    }

    .logo-twelth-position {
        position: absolute;
        right: -12%;
        bottom: -8%;
    }

}

@media only screen and (min-width: 481px) and (max-width: 768px) and (min-height: 700px) {
    /*brown #99774D*/
    /* setting up for Pixel 4L might need to split after this. 512w x 769h */

    .new-line {
        margin-top: -60px;
    }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one brown 481*/

    .page-one-background {
        height: auto;
        width: 100%;
        position: relative;
    }

    .logo-one {
        margin-top: 30px;
        margin-bottom: 50px;
        margin-left: 40px;
        height: 80px;
        width: 125px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 69%;
        top: 4%;
    }

    .colby-name {
        font-size: 24px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 15px;
        margin-top: -10px;
        line-height: 1.7;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 12%;
        left: -32px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 290px;
        width: auto;
    }

    .name-text {
        font-size: 35px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 30px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #99774D; /*#99774D brown DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two brown 481*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three brown 481*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four brown 481*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five brown 481*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 70px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 17%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six brown 481*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven brown 481*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight brown 481*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 8%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine brown 481*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 55%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten brown 481*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven brown 481*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve brown 481*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (max-height: 699px) {
    /*gold #D7B740*//* for wrap in capsul* /
    /* FIX about to upload and noticed this was max-height; 600px so gap, updating for now to 699 but check*/
    /* mmm 768 is ipads and 1024 is ipad pro - might need to change it but when turned sideways is less*/
    /* based on this for now - setting max height at 600px but check simulator after for full-wrap - both stil*/
    /* ipad pro triggered - compact - wrap in capsule - emulator for web - w 600 vs 700 should only trigger now in landscape mode*
    /* but it's still triggering even though height shows as 840px */
    /* but if it does would also need to check on a real device since ios app has smaller viewport unless height is the same - verify */
    /* my laptop 11" macair triggered - compact - wrap in capsule */

    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one gold 769*/

    .page-one-background {
        height: 30%;
        width: 76%;
        left: 12%;
        top: -4%;
        position: relative;
    }

    .logo-one {
        margin-top: 2px;
        margin-bottom: 50px;
        margin-left: 90px;
        height: 60px;
        width: 110px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 71%;
        top: 3%;
    }

    .colby-name {
        font-size: 18px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        top: 57%;
        left: 36px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 230px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        width: 100%;
        position: fixed;
        bottom: 5%;
        line-height: 0.9;
        left: 51%;
        margin-top: 0%;
        padding-top: 0%;
        margin-left: 0px;
        /*color: #D7B740; /*gold DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two gold 769*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: -2%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 24px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 24px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page three gold 769*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 32px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
        margin-top: -4%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 30px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four gold 769*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 11%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 23px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 27px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page five gold 769*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        margin-top: -3%;
        padding-left: 23%;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        padding-left: 15%;
        padding-bottom: 3%;
        padding-top: 2%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: 2200px;
        width: 1200px;
        margin-right: -50%;
        margin-left: 45%;
        margin-top: -480%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 25px;
        text-align: start;
        margin-left: 100px;
        padding-right: 80px;
        line-height: 1.0;
        margin-top: -171%; /*  the smaller the negative the lower it goes */
        /* for margin-top -171% - good for .rollon-product-image-height: 2200, width 1200, margin-left 45%, margin-top -480% */
    }

    /*page six gold 769*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: -2%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 23px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 24px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 24px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        /*right: 2%;*/
        height: auto;
        width: 230px;
        left: 50%;
        margin-left: -130px;
    }

    /*page seven gold 769*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 38px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: left;
        padding-left: 31%;
        width: 50%;
        margin-left: 10%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -32%;
        margin-left: 10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 24px;
      text-align: start;
      margin-top: -20%;
      margin-left: -10%;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight gold 769*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: -3.4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 25px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 24px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 25px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.8;
    }

    /*page nine gold 769*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 28px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 53%;
        width: 60%;
        left: 50%;
        margin-left: -30%;
    }

    /*page ten gold 769*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 27px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 30px;
        line-height: 1.6; /*was 1.5 if too low change back to 1.5*/
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven gold 769*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 24px;
        text-align: center;
        padding-top: 2%;
        padding-bottom: -10px;
        padding-left: 30px;
        margin-top: -82%;
        line-height: 1.0;
    }

    .tincture-product-image {
        width: auto; /*1200px*/
        height: 1080px;
        margin-left: 70%;
        margin-right: -40%;
        padding-top: 0px;
        margin-top: -265%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 26px;
        text-align: left;
        margin-left: 32%;
        margin-top: 3%;
        margin-bottom: 1%
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        width: 50%;
        font-size: 23px;
        text-align: start;
        margin-left: 32%;
        margin-right: -1%;
        line-height: 1.2;
    }

    /*page twelve gold 769*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: -2%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 28px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 28px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 29px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 28px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.7;
    }

    .logo-twelth-position {
        position: absolute;
        right: -40%;
        bottom: 20%;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (min-height: 700px) {
    /*bright blue #BFEFFF*/
    /* mmm 768 is ipads and 1024 is ipad pro - might need to change it but */
    /* based on this for now - setting min height at 701px, 1 higher than previous breakpoint so full-wrap sb here but check simulator-yes so far */
    /* was @media only screen and (min-width: 960px) */

    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one bright blue 769*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #BFEFFF; /*bright blue DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two bright blue 769*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three bright blue 769*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four bright blue 769*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five bright blue 769*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six bright blue 769*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven bright blue 769*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight bright blue 769*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine bright blue 769*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 60%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten bright blue 769*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven bright blue 769*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve bright blue 769*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    /*lime green #C0FF00/
    /* was @media only screen and (min-width: 960px) */
    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one lime green 1025*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -10px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #C0FF00; /*lime green DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two lime green 1025*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three lime green 1025*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 49%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four lime green 1025*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five lime green 1025*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -10%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six lime green 1025*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven lime green 1025*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight lime green 1025*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 10%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine lime green 1025*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 55%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten lime green 1025*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven lime green 1025*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1100px;
        height: auto;
        padding-left: 50px;
        padding-top: 20px;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve lime green 1025*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }
}

@media only screen and (min-width: 1201px) {
    /*lavender #b57edc*/
    /* was @media only screen and (min-width: 960px) */
    .new-line {
        margin-top: -60px;
      }

    .logo-size {
        height: 60px;
        width: 120px;
    }

    /*page one lavender 1201*/

    .page-one-background {
        height: auto;
        width: 70%;
        left: 15%;
        top: -2%;
        position: relative;
    }

    .logo-one {
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: 100px;
        height: 70px;
        width: 120px;
    }

    .image-text {
        font-family: 'Impact';
        position: absolute;
        width: 100%;
        left: 68%;
        top: 4%;
    }

    .colby-name {
        font-size: 23px;
        color: rgb(255,102,51);
    }

    .sub-title {
        font-size: 12px;
        margin-top: -4px;
    }

    .roll-on-gel-position {
        position: absolute;
        bottom: 21%;
        left: 43px;
        margin: 0;
        padding: 0;
    }

    .roll-on-gel-image {
        height: 240px;
        width: auto;
    }

    .name-text {
        font-size: 29px;
    }

    .page-text {
        font-family: 'Impact';
        font-size: 25px;
        position: absolute;
        width: 100%;
        bottom: 7%;
        line-height: 1.1;
        left: 99%;
        /*color: #b57edc; /*lavender DO NOT DELETE LINE*/
        color: #FFFFFF; /*white DO NOT DELETE LINE*/
    }

    /*page two lavender 1201*/

    .second-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Danny\ Way\ 198x3\ Background.png');
        position: relative;
    }

    .second-image-text {
        position: absolute;
        bottom: 1%;
    }

    .danny-name {
        display: inline-block;
        font-family: 'Impact';
        color: #4BBFEB;
        font-size: 30px;
        padding-left: 20px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .danny-text {
        font-family: 'Impact';
        color: #D12028;
        font-size: 31px;
        white-space: pre !important;
        margin-left: 15px;
    }

    /*page three lavender 1201*/

    .product-text {
        font-family: 'Impact';
        font-weight: bold;
        color: black;
        font-size: 43px;
        padding-top: 70px;
        text-align: center;
    }

    .plan-b-roller-product {
        background-image: url("./wrapImages/Plan B Roller Image.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 46%;
    }

    .price-planb-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 43px;
        text-align: center;
        padding-bottom: 30px;
    }

    /*page four lavender 1201*/

    .fourth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Chase\ Webb\ 198x3\ Background.png');
        position: relative;
    }

    .logo-four-position {
        margin-top: 30px;
        margin-left: 30px
    }

    .fourth-page-product {
        position: absolute;
        top: 0;
    }

    .large-roller-img {
        position: absolute;
        bottom: 2%;
        right: 18%;
        height: 220px;
        width: auto;
    }

    .fourth-image-text {
        position: absolute;
        bottom: 18%;
    }

    .webb-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 37px;
        padding-right: 8px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.40);
    }

    .webb-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 9px;
        font-size: 29px;
    }

    .webb-page-text {
        font-family: 'Impact';
        font-size: 32px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page five lavender 1201*/

    .price-rollon-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 38px;
        text-align: left;
        margin-top: -2%;
        padding-left: 90px;
    }

    .rollon-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 30px;
        text-align: center;
        padding-left: 20%;
        padding-bottom: 3%;
        padding-top: 3%;
        line-height: 1.0;
    }

    .rollon-product-image {
        height: auto;
        width: 1800px;
        padding-left: 8%;
        margin-right: -10%;
        margin-top: -15%;
    }

    .rollon-text {
        font-family: 'Rockwell';
        color: #459F3D;
        font-size: 30px;
        text-align: start;
        margin-top: -2%;
        margin-left: 20px;
        padding-right: 20px;
        line-height: 1.0;
    }

    /*page six lavender 1201*/

    .logo-six-position {
        margin-top: 30px;
        margin-left: 30px;
    }

    .top-background {
        height: 40%;
    }

    .colby-bike-image {
        z-index: 5000;
        height: 108%;
        width: auto;
        margin-top: 40px;
        margin-left: -22%;
    }

    .sixth-image-text {
        position: absolute;
        bottom: 4%;
    }

    .colby-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 29px;
        padding-left: 30px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .colby-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: black;
        padding-left: 5px;
        font-size: 33px;

    }

    .colby-page-text {
        font-family: 'Impact';
        font-size: 31px;
        padding-left: 20px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    .colby-bottom-background {
        position: absolute;
        background-color: white;
        background: url("./wrapImages/ColbyRaha 198x3 BottomSceneryOnly.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        bottom: 0;
        height: 50%;
        width: 100%;
    }

    .salve-img {
        position: absolute;
        bottom: -6%;
        right: 2%;
        height: 230px;
        width: auto;
    }

    /*page seven lavender 1201*/

    .product-salve-text {
        font-family: 'RockwellExtraBold';
        color: black;
        font-size: 45px;
        padding-top: 70px;
        text-align: center;
        margin-bottom: -10px;
    }

    .price-salve-text {
        font-family: 'Serif';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: left;
        padding-left: 28%;
    }

    .salve-product-image {
        height: 500px;
        width: auto;
        margin-top: -10%;
    }

    .salve-text {
      font-family: 'Rockwell';
      font-style: italic;
      color: #459F3D;
      font-size: 30px;
      text-align: start;
      margin-top: -10%;
      margin-left: -90px;
      margin-right: 50px;
      line-height: 1.1;
      width: 50%;
    }

    /*page eight lavender 1201*/

    .eighth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Ampudia\ 2x3\ Background.png');
        position: relative;
    }

    .logo-size-eight {
        height: 65px;
        width: 130px;
    }

    .logo-eight-position {
        margin-top: 8%;
        margin-left: 6%;
    }

    .triple-rollon-img {
        position: absolute;
        bottom: 22%;
        right: 0%;
        height: 230px;
        width: auto;
    }

    .eighth-image-text {
        position: absolute;
        bottom: 5%;
    }

    .ampudia-page-name {
        display: inline-block;
        font-family: 'Impact';
        color: #F15922;
        font-size: 26px;
        padding-left: 34px;
        padding-right: 5px;
        margin-bottom: 15px;
        background-color: rgba(0,0,0,0.24);
    }

    .ampudia-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 7px;
        font-size: 25px;
    }

    .ampudia-page-text {
        font-family: 'Impact';
        font-size: 30px;
        padding-left: 22px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.5;
    }

    /*page nine lavender 1201*/

    .price-small-roller-text {
        font-family: 'Impact';
        font-weight: bold;
        color: #009900;
        font-size: 35px;
        text-align: center;
        padding-bottom: 0px;
    }

    .small-roller-image {
        background-image: url("./wrapImages/Roll-On Gel Mockup (3 bottles-Trans)-25percent.svg");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 50%;
        margin-top: 0%;
        width: 50%;
        left: 50%;
        margin-left: -25%;
    }

    /*page ten lavender 1201*/

    .tenth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url('./wrapImages/Koa\ Rothamn\ 19x3\ Background.png');
        position: relative;
    }

    .koa-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 30px;
        padding-left: 15px;
        padding-right: 8px;
        margin-top: 15px;
        margin-bottom: 12px;
        background-color: rgba(0,0,0,0.24);
    }

    .koa-text {
        font-family: 'Impact';
        color: white;
        font-size: 33px;
        white-space: pre !important;
        margin-left: 30px;
    }

    .tenth-image-text {
        position: absolute;
        top: 5%;
        width: 100%;
        height: 55%;
    }

    .logo-tenth-position {
        width: 60%;
        text-align: right;
        margin-top: -5px;
    }

    .logo-size-ten {
        height: 50px;
        width: auto
    }

    .tincture-img {
        position: absolute;
        bottom: 2%;
        right: 0%;
        height: 250px;
        width: auto;
    }

    .cbd-drop-img-size {
        height: 260px;
        width: auto;
    }

    .cbd-drop-img-left {
        position: absolute;
        top: 95%;
        right: -5%;
    }

    .cbd-drop-img-center {
        position: absolute;
        top: 100%;
        right: 5%;
    }

    .cbd-drop-img-right {
        position: absolute;
        top: 95%;
        right: 15%;
    }

    .tincture-position {
        position:absolute;
        bottom: 0;
        right: -5%;
        width: 100%;
        height: 100%;
    }

    /*page eleven lavender 1201*/

    .image-top {
        top: 0;
    }

    .tincture-secondary-text {
        font-family: 'RockwellBold';
        font-style: italic;
        font-weight: bold;
        color: black;
        font-size: 27px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: -10px;
        margin-left: -30px;
    }

    .tincture-product-image {
        width: 1000px;
        height: auto;
        padding-left: 50px;
        margin-top: -4%;
    }

    .price-tincture-text {
        font-family: 'Rockwell';
        font-weight: bold;
        color: #009900;
        font-size: 34px;
        text-align: left;
        margin-left: 50px;
    }

    .tincture-text {
        font-family: 'Rockwell';
        font-style: italic;
        color: #459F3D;
        font-size: 26px;
        text-align: start;
        margin-left: 50px;
        padding-right: 40px;
        line-height: 1.2;
    }

    /*page twelve lavender 1201*/

    .twelth-page-background {
        height: 100%;
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('./wrapImages/NickBruce\ 188x3\ Background.png');
        position: relative;
    }

    .twelth-image-text {
        position: absolute;
        bottom: 0%;
    }

    .nick-name {
        display: inline-block;
        font-family: 'Impact';
        color: rgb(255,102,51);
        font-size: 38px;
        padding-left: 15px;
        padding-right: 5px;
        margin-bottom: 5px;
        background-color: rgba(0,0,0,0.24);
    }

    .nick-separator {
      display: inline-block;
      font-family: 'Impact';
      color: white;
      font-size: 38px;
      padding-left: 0px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .nick-side {
        font-family: 'BigNoodleTitlingOpaque';
        color: white;
        padding-left: 0px;
        font-size: 39px;
    }

    .nick-text {
        font-family: 'Impact';
        color: white;
        font-size: 36px;
        white-space: pre !important;
        margin-left: 15px;
        line-height: 1.4;
    }

    .logo-twelth-position {
        margin-left: 25%%;
        margin-bottom: 10%;
    }

}
