.responsive {
    width:      100;
    height:     80;
    transition: 'contain';
    margin-top:  3;
    margin-left: -10;
    align-self:  'center'
}

.option:hover {
    cursor: pointer;
}

.option {
    width: auto !important;
    margin-top: 1px;
    height: 25px;
    margin-right:10px;
}

.option.external {
  filter: brightness(5);
  margin-right: 25px;
}

.option.external.first {
  filter: brightness(5);
  margin-left: 5px;
}

.option.external.last {
  filter: brightness(5);
  margin-right: 0px;
}

.option.inactive {
  filter: brightness(0) !important;
  cursor: unset;
}


.facebook-icon {
  padding-left: 5px;
}

.twitter-icon {
  margin-top: 3px;
  height: 21px;
  margin-left: -2px;
}

.opt-cont {
  padding-left: 5px;
}

.main-option {
    width: 35% !important;
    height: 30px;
    margin-left: 3px;
}

.social-carousel {
    padding-left: 44%;
    margin-left: 24px;
}


  @media (min-width: 320px) {
    .options-container {
      padding-left: 10px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -55px;
      position: absolute;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -20px;
      position: absolute;
      width: auto;
      height: 25px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-top: 4px;
      margin-left: 20px;
      position: absolute;
      width: auto;
      height: 22px;
    }
  }

  @media (max-width: 419px) {  /*iphone xs*/
    .options-container {
        padding-left: 15px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -55px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -20px;
      width: auto;
      height: 25px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-top: 4px;
      margin-left: 20px;
      width: auto;
      height: 22px;
    }

  }

@media (min-width: 420px) {   /*xphone 13 pro*/
  .explore-container {
    padding: 0 !important;
    padding-left: 15px;
  }

  .option.external.first {
    filter: brightness(5);
    margin-left: -65px;
  }

  .option.external {
    filter: brightness(5);
    margin-left: -26px;
    width: auto;
    height: 26px;
  }

  .option.external.last {
    filter: brightness(5);
    margin-top: 4px;
    margin-left: 18px;
    width: auto;
    height: 22px;
  }
}

@media (min-width: 1200px) {
  #explore-container {
    width: 70%;
  }

  #explore-container {
    transition: 0.3s ease-in;
    transition-property: all;

  }

  .full-capsule {
    width: 100% !important;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

}

@media (min-width: 900px) {  /*my mal laptop*/
  .options-container {
      padding-left: 19px;
  }

  .option.external.first {
    filter: brightness(5);
    left: 70px;
    position: absolute;
  }

  .option.external {
    filter: brightness(5);
    margin-left: -80px;
    position: absolute;;
  }

  .option.external.last {
    filter: brightness(5);
    margin-left: -30px;
    position: absolute;
  }
}

  @media (min-width: 1024px) { /*mac laptop*/
    .options-container {
      padding-left: 39px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -100px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -100px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-right: 0px;
    }

  }

  @media (min-width: 1440px) {
    .options-container {
      padding-left: 39px;
    }

    .option.external.first {
      filter: brightness(5);
      margin-left: -150px;
    }

    .option.external {
      filter: brightness(5);
      margin-left: -140px;
    }

    .option.external.last {
      filter: brightness(5);
      margin-left: -60px;
    }
  }


.capsule-name {
    text-align: left;
}

.active-option {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -3px;
}

.container-width {
  max-width: 650px;
  left: 50%;
}

@media(max-height: 910px) {
    .capsule-view-0 {
      height: calc(var(--vh, 1vh) * 55);
    }
  }

  @media(min-height: 910px) {
    .capsule-view-0 {
      height: calc(var(--vh, 1vh) * 55);
    }

  }

  .capsule-view-0 {
    height: calc(var(--vh, 1vh) * 75);
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .capsule-view-1 {
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: calc(var(--vh, 1vh) * 90);
  }

  .capsule-view-2 {

   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: calc(var(--vh, 1vh) * 95);
  }

  .capsule-view-3 {

    height: calc(var(--vh, 1vh) * 86);
   /* border: 1px solid rgb(82, 81, 81); */
    border-radius: 10px;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .capsule-view.opened {
      height: calc(var(--vh, 1vh) * 75);
      overflow: auto;
  }

.divider {
    text-align: center;
    margin: 5px;
    height: calc(var(--vh, 1vh) * 3);
    cursor: pointer;
}

.divider.explore {
  text-align: center;
  margin: 5px;
  height: calc(var(--vh, 1vh) * 0);
  cursor: pointer;
}

.carousel-container.social {
    padding-top: 5px;
    height: calc(var(--vh, 1vh) * 5);
}

.carousel-container.create {
  padding-top: 5px;
  height: calc(var(--vh, 1vh) * 5);
}

.carousel-container.navigation {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: white;
    text-align: center;
}

.header {
    height: calc(var(--vh, 1vh) * 5);
    color: white;
}

.right {
    text-align: right;
}

.left {
  text-align: left;
}

.search-icon-input {
  height: 20px;
  width: 20px;
}

.mute {
  height: 1px;
  position: relative;
  bottom: 35px;
}

.group-search-assets-left {
  border: 1px solid black;
  border-right-style: none;
  background: transparent;
  color: white;
}

.group-search-assets-right {
  border: 1px solid black;
  border-left-style: none;
  background: transparent;
  color: white;
}

.categoryCarousel {
    padding-right: 0px;
    overflow: hidden;
}

.left-icon {
    text-align: left !important;
}

.center-icon {
    text-align: center;
}

.right-icon {
    text-align: right;
}



@media (max-height: 1080px) {
    .navigation-img {
       width: 140px !important;
    }

  }

  @media (max-height: 1024px) {
    .navigation-img {
       width: 118px !important;
    }

  }

  @media (max-height: 841px) {
    .navigation-img {
       width: 105px !important;
    }
  }

@media (max-height: 840px) {
    .navigation-img {
       width: 100px !important;
    }
  }

  @media (max-height: 736px) {
    .navigation-img {
      margin-top: 5px !important;
       width: 95px !important;
    }
  }

  @media (max-height: 731px) {
    .navigation-img {
      margin-top: 6px !important;
       width: 95px !important;
    }
  }

  @media (max-height: 672px) {
    .navigation-img {

        width: 80px !important;
     }
  }

  @media (max-height: 568px) {
    .navigation-img {
      margin-top: 5px !important;
        width: 75px !important;
     }
  }

  @media (max-height: 490px) {
    .navigation-img {
        width: 60px !important;
     }
  }

.active-option.navigation{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    z-index: 1;
    margin-top:2px;
    height: calc(var(--vh, 1vh) * 8);
}

.navigation-container {
  height: calc(var(--vh, 1vh) * 8);
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-img {
  height: calc(var(--vh, 1vh) * 7);
    width: 127px;
    font-size: 12px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 6px;
    margin-left:auto;
    margin-right:auto;

}

.no-pic {
  background-image: url('../../assets/images/no_pic.jpeg');
  background-size: 100% 100%;
}

.navigation-name-on-pic {

    display: block;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1px;
    font-size:12px;
    cursor: pointer;

}

.p-0 {
    padding: 0;
}

/* Transformation classes for orientation data */

.trans-mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.trans-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.trans-mirror-rotate-180 {
    -webkit-transform: scaleX(-1) rotate(180deg);
    transform: scaleX(-1) rotate(180deg);
}

.trans-mirror-rotate-90 {
    -webkit-transform: scaleX(-1) rotate(90deg);
    transform: scaleX(-1) rotate(90deg);
}

.trans-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.trans-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.trans-mirror-rotate-270 {
    -webkit-transform: scaleX(-1) rotate(270deg);
    transform: scaleX(-1) rotate(270deg);
}

.fullscreen-btn {
  border-radius: 10px;
  background: rgb(171,171,171);
  background: linear-gradient(0deg, rgba(171,171,171,1) 0%, rgba(204,201,201,1) 56%, rgba(255,255,255,1) 100%);
  outline: none;
  border: none;
}

.fullscreen-btn:active {
  background: rgba(171,171,171,0.8);
}

.fullscreen-btn-container {
  padding-top: 8px;
  pointer-events: auto;
  z-index: 10;
}

.gradient-background {
    background: -moz-linear-gradient(left, rgba(238,238,238,0.05) 0%, rgba(255,255,255,0.37) 50%, rgba(252,252,252,0.05) 100%);
    background: -webkit-linear-gradient(left, rgba(238,238,238,0.05) 0%,rgba(255,255,255,0.37) 50%,rgba(252,252,252,0.05) 100%);
    background: linear-gradient(to right, rgba(238,238,238,0.05) 0%,rgba(255,255,255,0.37) 50%,rgba(252,252,252,0.05) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0deeeeee', endColorstr='#0dfcfcfc',GradientType=1 );

}

.iframe {
    -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      overflow-x: hidden;
     *width: 1px;
    min-width: 100%;

}

iframe {
    *width: 1px;
    min-width: 100%;
    height: 100%;
    width: 100%;

}

.section-background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color:rgba(0, 0, 0, 0.8);
  pointer-events: none
}

.not-draggable {
  pointer-events: none;
  touch-action: none;
}

.bot-padding {
  padding-left: 0px;
  padding-right: 0px;
}

#lin-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8)), linear-gradient(to top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8));
}

.featured-img {
  /*height: 350px;
  width: 250px;*/
  margin: 0 auto;
  border-radius: 15px;
}

@media (min-width: 720px) {
  .featured-img {
    height: 340px;
    width: 255px;
  }
}

/* need to verify for device size */
@media (max-width: 480px) {
  .featured-img {
    height: 290px;
    width: 218px;
  }
}

/* iphoneXS - looks good */
@media (max-width: 375px) {
  .featured-img {
    height: 280px;
    width: 215px;
  }
}

/* need to verify for device size */
@media (max-width: 320px) {
  .featured-img {
    height: 280px;
    width: 215px;
  }
}

.home-img {
  cursor: pointer;
  margin: auto;
  border-radius: 10px;
}

@media (min-width: 720px) {
  .home-img {
    height: 175px;
    width: 125px;
  }
}

@media (max-width: 480px) {
  .home-img {
    height: 125px;
    width: 100px;
  }
}

@media (max-width: 375px) {
  .home-img {
    height: 100px;
    width: 75px;
  }
}

@media (max-width: 320px) {
  .home-img {
    height: 100px;
    width: 70px;
  }
}

.featured-sm-img {
  border-radius: 100px;
}

@media (min-width: 720px) {
  .featured-sm-img {
    height: 125px;
    width: 125px;
  }
}

@media (max-width: 480px) {
  .featured-sm-img {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 375px) {
  .featured-sm-img {
    height: 75px;
    width: 75px;
  }
}

@media (max-width: 320px) {
  .featured-sm-img {
    height: 70px;
    width: 70px;
  }
}

@media only screen and (max-width: 760px) {
  .slick-arrow {
    display: none !important;
  }
}

.home-navigation {
  margin: auto;
}

.home-navigation-wrapper {
  height: auto;
}

.home-navigation-wrapper > .slick-slider > .slick-list > .slick-track {
  margin-left: 0px !important;
}
