.footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 100000;
}

.footer.edit-footer {
  padding-top: 10px;
  background-color: rgba(0,0,0);
}

.footer-navigation {
  text-align: center !important;
}

.footer-navigation-img {
  height: 45px;
  width: auto;
}

.footer-navigation-img.contacts {
  height: 25px;
}

.footer-navigation-img.explore {
  height: 25px;

}

.contact-label {
  font-size: 11px;
  margin-top: 5px;
  letter-spacing: 1px;
  opacity: 0.8;
}
