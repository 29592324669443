.capsule-options {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
    background: white;
    overflow: hidden;
  }

  .capsule-options.scrollable {
    position: sticky;
    background-color: black;
    width: 100%;
  }


  @media (min-width: 375px) {
    .capsule-options {
      font-size:13px;
  }
}

.scrollable {
  overflow: auto !important;
}

.capsule-options-img {
  height: 22px;
  width:auto;
  filter: brightness(100%);
  user-select: none;
}

.capsule-options-img1 {
  height: 22px;
  width:auto;
  filter: brightness(0);
  user-select: none;
}

.text-black{
  color: black;
}

.text-white {
  color: white;
}

.capsule-options-img-disable {
  height: 22px;
  width:auto;
  color: grey;
  filter: brightness(75%);
  user-select: none;
}

@media (max-width: 360px) {
  .capsule-options {
    font-size:10px;
  }
}

.vertical-divider {
    height: 50px;
}

.media-card {
  margin-top: 15px;
  background-color: grey;
}

.chatbot-width {
  width: 90%;
}

.chatbot-standalone {
  height: 100vh;
  background-color: white;
}

.chatbot-standalone-ios {
  height: 90vh;
  background-color: white;
}

.media-images {
  height: 75px;
  width: 75px;
  border-radius: 5px;
  cursor: pointer;
}

.media-videos {
  border-radius: 15px;
  max-height: 75vh;
  max-width: 100%;
}

.media-list {
  padding-top: 15px;
      text-align: left;
      align-self: left;
      margin: 0;
  }

  .media-header {
      padding-bottom: 10px;
  }

  .media-bio {
    text-align: justify;
  }

  .media-option {
    margin-top: 15px;
  }

  .label {
    color: rgb(77, 76, 76);
  }

  #toggler:hover {
      cursor: pointer;
  }

  .media-icons {
    height: 20px;
    padding: 0;
  }

  .option-container {
    padding-left: 0px;
  }

  .icon-container {
    padding-right: 0px;
  }

  .closeButton {
    font-size: 14px;
  }

  .closeButton:hover {
    cursor: pointer;
  }

  /* capsule display - profile image */
  .capsule-pic {
    height: 75px;
    width: 75px;
    float: left;
    margin-right: 10px;
    border-radius: 10px;
  }

  .connect {
    height: 22px;
  }

  .bg-wh {
    background-color: white !important;
  }

#multichat-chat-box {
  background-color: white !important;
}

  #fanbot-chat-widget .fanbot-header {
    display: none !important;
    visibility:hidden !important;
  }

.capsule-pic.no-pic {
  background-color: rgba(255,255,255,0.3);
  height: auto;
  width: auto;
  max-width: 75px;
  max-height: 75px;
  min-width: 65px;
  min-height: 65px;
  margin-right: 10px;
}


  .capsule-pic-mini {
    width: 100px;
    height: 125px;
    border-radius: 10px;
    float:left;
    margin-right: 10px;
  }

  .capsule-header {
    position: relative;
  }

  .capsule-header.linear {
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(11,11,11,0.9248074229691877) 100%);
  }

  .add-card-container {
    text-align:center;
    z-index: 0;
  }

  .add-card-option {
    margin-top: 25px;
    cursor: pointer;
  }

  .add-card-option.create-new {
    margin-bottom: 65px;
    font-size: 20px;
  }

  .add-card-intro {
    margin-bottom: 40px;
    margin-top: 15px;
  }

  .add-card-cancel {
    position:sticky;
    position: -webkit-sticky;
    bottom:0;
    z-index: 5555;
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid grey;
    cursor: pointer;
  }

  .new-group-input {
    display: block;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none !important;
      width: 50%;
      text-align: center;
      align-self: center;
      margin: 0 auto;
      outline-color: transparent;
      outline-style: none;
  }

.form-control:focus {
    outline-color: transparent !important;
    outline-style: none !important;
  }

  .group-create-success {
    max-height: 100px;
    width: auto;
    margin-bottom: 15px;
  }

  .vertical-align {
    text-align: center;
    padding: 0;
  }

  .capsule-view.bottom-view {
    height: calc(var(--vh, 1vh) * 20);
    margin-top: 20px;
    position: relative;
    background-color: white;
    opacity: 0.8;
    color: black;
    text-align: center;
    align-items: center;
  }

  .share-image {
    width:auto;
    max-height: 50px;
  }

  .share-cancel {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    border-top: 1px solid grey;
    cursor: pointer;
  }

  .qr-code {
    box-sizing: content-box !important;
  }

  .option-text {
    white-space: nowrap;
    margin-top: 5px;
    font-weight: bold;
    color: #3399ff;
    user-select: none;
  }

  .option-text-disable {
    white-space: nowrap;
    margin-top: 5px;
    font-weight: bold;
    color: #C0C0C0;
    user-select: none;
  }

  .react-viewer-image {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
    transform: none !important;
  }

  .video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    pointer-events: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

.video-background-blur {
  filter: blur(15px);
}

.loader {
  position: absolute;
  top: 45%;
  left: 45%;
}

.draggable {
  height: calc(var(--vh, 1vh) * 35);
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-height: 780x) {
  .content-container {
    height: 54% !important;
}
}

#capsule-view {
  transition: height 0.3s ease-in;
}

.content-container {
  height: 67%;
  overflow-y: auto;
  overflow-x: hidden;
}

.white-arrow {
  filter: brightness(5);
}

.white-arrow.nav-button {
  filter: brightness(10);
}

.capsule-container-max {
  max-height: 100%;
}

.capsule-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capsule-footer-option {
  border-right: 2px solid #78797B;
  border-left: 2px solid #78797B;
}

.selected {
  background: grey !important;
  cursor: pointer;
}

.unselected:hover {
  background: lightgrey !important;
  cursor: pointer;
}

.disable-button {
   cursor: default
}

.overflow-hidden {
  overflow: hidden;
}

.vcard-title {
  color: darkgrey;
}

.share-spacing {
  padding-top: 20px;
}

.share-back-notes {
  font-size: 13px;
}

.share-back-spacing {
  padding-top: 80px;
}

.pos-rel {
  position: relative;
  overflow: hidden;
}

#fanbot-chat-widget .container {
  padding-left: 0px;
  padding-right: 0px;
}

#fanbot-chat-widget .input-wrapper .container {
  padding-left: 0px;
  padding-right: 0px;
}

.share-margin {
  margin-top: 2
}
  .footer-arrow.opened {
    margin-bottom: 5px;
    height: 10px;
  }

  .footer-arrow.closed {
    transform: rotate(180deg);
    height: 10px;
  }

  .col-center {
    display: flex;
    align-items: center;
  }

  .col-center.left {
    text-align: right;
  }


  .col-center.right {
    text-align: left;
  }

  .wrap-container {
    height: 100%;
    overflow: hidden;
  }

  .fullscreen-button {
    position: absolute;
    z-index: 5;
  }

  @media (max-width: 799px) {
    .days-spacing {
      margin-left: -4%;
    }
  }

  @media (min-width: 800px) and (max-width: 999px) {
    .days-spacing {
      margin-left: -2%;
    }
  }

  @media (min-width: 1000px) {
    .days-spacing {
      margin-left: -3%;
    }
  }

  @media {
    .hours-spacing {
      margin-left: 3%;
    }
  }

  @media {
    .minutes-spacing {
      margin-left: 3%;
    }
  }

  .duration-spacing {
    padding-top: 0px;
  }
